import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { SignInSchema, FormFields } from '../../schemas/SignInSchema';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signIn } from '../../redux/actions/authActions';
import { Link } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { AppDispatch } from '../../redux/store';
import TextField from '../common/Fields/TextField';
import PasswordField from '../common/Fields/PasswordField';

const SignInForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state: RootState) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(SignInSchema),
  });

  const onSubmit = (data: FormFields) => {
    dispatch(signIn(data.email, data.password, navigate));
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      {error && (
        <div className="text-red-500 flex justify-center text-sm mt-2">
          {error}
        </div>
      )}

      {/* Use TextField for email */}
      <TextField
        register={register('email')}
        placeholder="Email"
        error={errors.email}
      />

      {/* Use PasswordField for password */}
      <PasswordField
        register={register('password')}
        placeholder="Password"
        error={errors.password}
      />

      <div className="flex justify-end">
        <Link
          to="/forgot-password"
          className="text-primary-600 font-semibold text-sm"
        >
          Forgot Password?
        </Link>
      </div>

      <button
        type="submit"
        disabled={loading}
        className="w-full border border-transparent bg-primary-600 text-white text-lg py-2 rounded-md hover:bg-primary-700 transition disabled:bg-gray-400 disabled:cursor-not-allowed"
      >
        {loading ? 'Signing In...' : 'Let Me In!'}
      </button>
    </form>
  );
};

export default SignInForm;
