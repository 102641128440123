import React from 'react';

interface ParallelogramImageProps {
  imgUrl: string;
  additionalClass: string;
  index: number;
}

const ParallelogramImage: React.FC<ParallelogramImageProps> = ({
  imgUrl,
  additionalClass,
  index,
}) => {
  return (
    <div
      className={`relative w-full clip-parallelogram ${additionalClass}`}
      //   style={{
      //     transform:
      //       index === 0 ? 'translateX(0)' : `translateX(calc(-20% * ${index}))`,
      //   }}
    >
      <img
        src={imgUrl}
        alt="Styled Parallelogram"
        className="w-full opacity-70 hover:opacity-100 hover:scale-105 transition duration-300"
      />
    </div>
  );
};

export default ParallelogramImage;
