import React, { useState, useEffect } from 'react';
import { fetchProvinces, fetchCities } from '../services/locationService';

interface ProvinceCitySelectorProps {
  initialProvince: string;
  initialCity: string;
  onProvinceChange: (province: string) => void;
  onCityChange: (city: string) => void;
}

const ProvinceCitySelector: React.FC<ProvinceCitySelectorProps> = ({
  initialProvince,
  initialCity,
  onProvinceChange,
  onCityChange,
}) => {
  const [provinces, setProvinces] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [selectedProvince, setSelectedProvince] =
    useState<string>(initialProvince);
  const [selectedCity, setSelectedCity] = useState<string>(initialCity);

  useEffect(() => {
    const loadProvinces = async () => {
      const provinces = await fetchProvinces();
      setProvinces(provinces);

      if (initialProvince) {
        const provinceCode = provinces.find(
          (province: { name: string }) => province.name === initialProvince,
        )?.code;
        if (provinceCode) {
          const cities = await fetchCities(provinceCode);
          setCities(cities);
        }
      }
    };
    loadProvinces();
  }, [initialProvince]);

  const handleInputChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;

    if (name === 'province') {
      setSelectedProvince(value);
      setSelectedCity('');

      const provinceCode = provinces.find(
        (province) => province.name === value,
      )?.code;

      if (provinceCode) {
        const cities = await fetchCities(provinceCode);
        setCities(cities);
      } else {
        setCities([]);
      }

      onProvinceChange(value);
    } else if (name === 'city') {
      setSelectedCity(value);
      onCityChange(value);
    }
  };

  return (
    <div className="space-y-4">
      {/* Province Dropdown */}
      <select
        name="province"
        value={selectedProvince}
        onChange={handleInputChange}
        className="w-full bg-gray-200 placeholder:text-gray-400 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600"
      >
        <option value="">Pilih Provinsi</option>
        {provinces.map((province) => (
          <option key={province.code} value={province.name}>
            {province.name}
          </option>
        ))}
      </select>

      {/* City Dropdown */}
      {selectedProvince && (
        <select
          name="city"
          value={selectedCity}
          onChange={handleInputChange}
          className="w-full bg-gray-200 placeholder:text-gray-400 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600"
        >
          <option value="">Pilih Kota</option>
          {cities.map((city) => (
            <option key={city.code} value={city.name}>
              {city.name}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default ProvinceCitySelector;
