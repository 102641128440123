import axios from 'axios';
import http from '../services/httpService';
import { AppDispatch } from '../store';
import { toast } from 'react-toastify';

type forgotPasswordData = {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
};

interface SendTokenData {
  email: string;
}

export const forgotPassword =
  (data: forgotPasswordData, navigate: any) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: 'FORGOT_PASSWORD_REQUEST' });

    try {
      const response = await http.post('/reset-password', {
        email: data.email,
        token: data.token,
        password: data.password,
        password_confirmation: data.password_confirmation,
      });

      if (response.status === 200) {
        dispatch({
          type: 'FORGOT_PASSWORD_SUCCESS',
        });

        toast.success('Forgot password successful! Redirecting to login', {
          autoClose: 3000,
          pauseOnHover: false,
          onClose: () => {
            dispatch({ type: 'FORGOT_PASSWORD_LOADING_FINISHED' });
            navigate('/auth');
          },
        });
      }
    } catch (error) {
      let errorMessage = 'An unexpected error occured.';

      if (axios.isAxiosError(error) && error.response) {
        // Extract the error message
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = Object.values(error.response.data.message).join(', ');
        }
      }

      dispatch({
        type: 'FORGOT_PASSWORD_FAILURE',
        payload: errorMessage,
      });

      toast.error(errorMessage);
    }
  };

export const sendToken =
  (data: SendTokenData) => async (dispatch: AppDispatch) => {
    dispatch({ type: 'SEND_TOKEN_REQUEST' });

    try {
      const response = await http.post('/forgot-password', {
        email: data.email,
      });

      if (response.status === 200) {
        dispatch({
          type: 'SEND_TOKEN_SUCCESS',
        });

        toast.success(
          'Reset link sent successfully! Please check your email.',
          {
            autoClose: 3000,
            pauseOnHover: false,
          },
        );

        dispatch({ type: 'SEND_TOKEN_LOADING_FINISHED' });

        return { success: true };
      }
    } catch (error) {
      let errorMessage = 'An unexpected error occurred.';

      if (axios.isAxiosError(error) && error.response) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = Object.values(error.response.data.message).join(', ');
        }
      }

      dispatch({
        type: 'SEND_TOKEN_FAILURE',
        payload: errorMessage,
      });

      toast.error(errorMessage);
    }
  };
