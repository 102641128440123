import { AppDispatch } from '../store';
import http from '../services/httpService';
import { toast } from 'react-toastify';

export const fetchUpcomingEvents =
  (
    page: number = 1,
    perPage: number = 8,
    searchQuery: string = '',
    status: string = 'published',
    replace: boolean = false,
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: 'UPCOMING_EVENTS_REQUEST' });

    try {
      const response = await http.get(
        `/event-upcoming?timeFilter=upcoming&perPage=${perPage}&page=${page}&status=${status}&search=${searchQuery}`,
      );
      const data = response.data;

      dispatch({
        type: 'UPCOMING_EVENTS_SUCCESS',
        payload: {
          events: data.data.data,
          pagination: {
            currentPage: data.data.current_page,
            lastPage: data.data.last_page,
            total: data.data.total,
            nextPage: data.data.next_page_url,
            prevPage: data.data.prev_page_url,
          },
          replace,
        },
      });
    } catch (error: any) {
      toast.error(
        error.response?.data?.message || 'Failed to fetch user events.',
      );
      dispatch({
        type: 'UPCOMING_EVENTS_FAILURE',
        payload: error.response?.data?.message || 'Something went wrong',
      });
    }
  };

export const createEvent =
  (eventData: any) => async (dispatch: AppDispatch) => {
    dispatch({ type: 'CREATE_EVENT_REQUEST' });

    try {
      const formData = new FormData();
      formData.append('title', eventData.title);
      formData.append('description', eventData.description);
      formData.append('type', eventData.type);
      formData.append('start_time', eventData.startTime);
      formData.append('end_time', eventData.endTime);
      formData.append('max_participants', eventData.maxParticipants);
      formData.append('status', eventData.status);
      formData.append('location', eventData.location);
      formData.append('location_url', eventData.locationUrl);
      if (eventData.organizer) {
        formData.append('organizer', eventData.organizer);
      }

      if (eventData.image) {
        formData.append('image', eventData.image);
      }

      const response = await http.post('/admin/event/create', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.data.success) {
        dispatch({
          type: 'CREATE_EVENT_SUCCESS',
          payload: response.data.data,
        });

        toast.success('Event created successfully');

        return { type: 'CREATE_EVENT_SUCCESS' };
      }
    } catch (error: any) {
      let errorMessages: string[] = [];

      if (error.response && error.response.data.message) {
        const errors = error.response.data.message;
        errorMessages = Object.values(errors)
          .flat()
          .map((msg) => `⚠️ ${msg}`); // Format messages with warning sign
      } else {
        errorMessages.push('Failed to create event. Please try again.');
      }

      dispatch({
        type: 'CREATE_EVENT_FAILURE',
        payload: errorMessages,
      });

      return { type: 'CREATE_EVENT_FAILURE', errors: errorMessages };
    }
  };

export const updateEvent =
  (id: string, eventData: any) => async (dispatch: AppDispatch) => {
    dispatch({ type: 'EVENT_UPDATE_REQUEST' });

    try {
      const response = await http.put(`/admin/event/${id}`, eventData);
      dispatch({ type: 'EVENT_UPDATE_SUCCESS', payload: response.data });
      toast.success('Event updated successfully!');
    } catch (error: any) {
      dispatch({ type: 'EVENT_UPDATE_FAILURE', payload: error.message });
      toast.error('Failed to update event');
    }
  };

export const deleteEvent = (id: string) => async (dispatch: AppDispatch) => {
  dispatch({ type: 'EVENT_DELETE_REQUEST' });

  try {
    await http.delete(`/admin/event/delete/${id}`);
    dispatch({ type: 'EVENT_DELETE_SUCCESS' });
    toast.success('Event deleted successfully!');
  } catch (error: any) {
    dispatch({ type: 'EVENT_DELETE_FAILURE', payload: error.message });
    toast.error('Failed to delete event');
  }
};

export const resetUpcomingEvents = () => ({
  type: 'RESET_UPCOMING_EVENTS',
});
