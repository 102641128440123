const Copyright = () => {
  return (
    <small className="text-2xs mb-4 w-full max-w-2xl mx-auto block">
      CoppaMagz | CEO William Yuswan | Call center 0821-1242-7255 (only
      available in Indonesia) | E-mail support@community.coppamagz.com | Address
      Jl. Kedoya Alamanda V No.10 Blok E6, RT.16/RW.4, South Kedoya, Kebonjeruk,
      West Jakarta City, Jakarta 11520 ©️ 2025 PT. Roro Jaya Utama or its
      affiliates all rights reserved. | Ver. 1.0.0
    </small>
  );
};

export default Copyright;
