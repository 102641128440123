import axios from 'axios';

const http = axios.create({
  baseURL: 'https://inidevloh.coppamagz.com/testpingtestpin12345/api',
  timeout: 10000,
});

http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      localStorage.removeItem('authToken');
    }
    return Promise.reject(error);
  },
);

export default http;
