import React, { useEffect, useState } from 'react';
import EventBannerImages from './EventBannerImages';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { fetchUpcomingEvents } from '../../redux/actions/fetchUpcomingEvents';
import isAdmin from '../../utilities/getRole';

const EventBanner: React.FC<{ setSearchValue?: (value: string) => void }> = ({
  setSearchValue,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchQuery, setSearchQuery] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    setStatus(isAdmin() ? '' : 'published');

    return () => {
      setStatus('published');
    };
  }, []);

  const handleSearch = () => {
    dispatch(fetchUpcomingEvents(1, 8, searchQuery, status, true));
    if (setSearchValue) {
      setSearchValue(searchQuery);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="bg-[#3EB64930] flex flex-col lg:flex-row items-center justify-between rounded-xl overflow-hidden text-center lg:text-left">
      <div className="space-y-2 w-full md:w-4/5 py-8 2xl:py-16 px-10">
        <div className="lg:-space-y-4">
          <h1 className="text-3xl font-bold text-primary-600 text-stroke-pink-43 fredoka-600">
            Welcome to
          </h1>
          <img
            src="/assets/logo.png"
            alt="coppamagz community"
            className="h-18 md:h-20 mx-auto lg:mx-0"
          />
        </div>
        <div className="flex gap-2 items-center w-full lg:w-4/5">
          <input
            type="text"
            placeholder="Search for Events..."
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            className="p-3 rounded-xl text-xs border-1 border-primary-600 w-60 focus:outline-none flex-grow"
          />
          <button
            onClick={handleSearch}
            className="bg-primary-600 text-white p-2 rounded-xl hover:bg-primary-700 transition"
          >
            <img
              src="/assets/icons/search.png"
              alt="search button"
              className="h-5"
            />
          </button>
        </div>
        <p className="text-base md:text-lg text-[#121212] poppins-regular">
          Join the most up-to-date K-pop Community events! Attend events and
          earn rewards!
        </p>
        <button className="bg-white px-4 py-2 rounded-lg text-sm text-primary-550 font-semibold border-1 border-primary-600">
          Learn More
        </button>
      </div>
      <div className="flex w-full">
        <EventBannerImages />
      </div>
    </div>
  );
};

export default EventBanner;
