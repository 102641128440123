const getCurrentWIBTime = () => {
  const now = new Date();
  const utcTime = now.getTime(); // Convert to UTC
  const WIBOffset = 7 * 60 * 60000; // WIB is UTC +7
  const wibTime = new Date(utcTime + WIBOffset); // Convert to WIB

  return wibTime.toISOString().slice(0, 16); // Format as ISO string and remove seconds/milliseconds
};

export default getCurrentWIBTime;
