interface Event {
  id: number;
  event_id: number;
  registered_at: string;
  status: string;
  checkin_string: string;
  event: null | {
    id: number;
    title: string;
    slug: string;
    description: string;
    image: string;
    location: string;
    start_time: string;
    end_time: string;
    type: string;
    organizer: string;
    max_participants: number | null;
    status: string;
  };
}

interface Pagination {
  currentPage: number;
  lastPage: number;
  total: number;
  nextPage: string | null;
  prevPage: string | null;
}

interface UserEventsState {
  loading: boolean;
  events: Event[];
  upcomingEvents: Event[];
  pagination: Pagination | null;
  error: string | null;
}

const initialState: UserEventsState = {
  loading: false,
  events: [],
  upcomingEvents: [],
  pagination: null,
  error: null,
};

const userEventsReducer = (
  state = initialState,
  action: any,
): UserEventsState => {
  switch (action.type) {
    case 'USER_EVENTS_REQUEST':
      return { ...state, loading: true, error: null };
    case 'USER_EVENTS_SUCCESS':
      return {
        ...state,
        loading: false,
        events: [...state.events, ...action.payload.events],
        pagination: action.payload.pagination,
      };
    case 'USER_UPCOMING_EVENTS_SUCCESS':
      return {
        ...state,
        loading: false,
        upcomingEvents: [...state.upcomingEvents, ...action.payload.events],
        pagination: action.payload.pagination,
      };
    case 'RESET_USER_EVENTS':
      return {
        ...initialState,
      };
    case 'USER_EVENTS_FAILURE':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default userEventsReducer;
