import React from 'react';
import isAdmin from '../../utilities/getRole';

interface SectionTitleProps {
  title: string;
  titleBadge: string;
  buttonTitle?: string;
  onButtonClick?: () => void;
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  titleBadge,
  buttonTitle,
  onButtonClick,
}) => {
  return (
    <div className="flex items-center gap-4 mb-4 fredoka-600">
      <h2 className="text-3xl lg:text-5xl font-bold">{title}</h2>
      <img
        src={`/assets/${titleBadge}.png`}
        alt="events logo"
        className="h-12 lg:h-16"
      />
      {buttonTitle && isAdmin() && (
        <button
          onClick={onButtonClick}
          className="inter bg-white border-1 border-primary-600 text-[#F27BA2] rounded-xl p-2 px-4 hover:bg-gray-100 transition"
        >
          Add {buttonTitle}
        </button>
      )}
    </div>
  );
};

export default SectionTitle;
