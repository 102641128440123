import { Link } from 'react-router-dom';
import isAdmin from '../../utilities/getRole';

interface EventCardProps {
  imgSrc: string;
  title: string;
  location: string;
  date: string;
  attendance: number;
  slug: string;
  status?: string;
}

const EventCard: React.FC<EventCardProps> = ({
  imgSrc,
  title,
  location,
  date,
  attendance,
  slug,
  status,
}) => {
  return (
    <Link
      to={`/event/${slug}`}
      className="event-card lg:max-w-[280px] aspect-[calc(1.1)/1] lg:min-w-[280px] 2xl:min-w-[360px] relative rounded-xl overflow-hidden w-full after:z-10
        after:content-[''] after:absolute after:w-full after:h-4/5 2xl:after:h-4/5 after:bg-no-repeat after:bg-gradient-to-b after:from-transparent after:via-[#021F66]/100 after:to-[#172957] after:left-0 after:bottom-0 after:rounded-b-xl
        before:cursor-pointer before:transition hover:before:z-30 hover:before:absolute hover:before:w-full hover:before:h-full hover:before:text-white hover:before:bg-no-repeat hover:before:bg-[#3EB64970] hover:before:left-0 hover:before:bottom-0
        hover:before:flex hover:before:justify-center hover:before:items-center hover:before:text-3xl
    "
    >
      <img
        src={imgSrc}
        alt={title}
        className="z-10 rounded-xl w-full max-h-[300px] overflow-hidden object-cover absolute"
      />
      <div className="relative z-20 h-full flex flex-col justify-end">
        <div className="p-1 px-2 2xl:py-2 mx-2 bg-pink-500 w-fit rounded-xl flex items-center gap-2">
          <img
            src="/assets/icons/people.png"
            alt="attendance"
            className="w-6 2xl:w-7"
          />
          <p className="text-lg 2xl:text-xl text-[#FFECC7]">{attendance}</p>
        </div>
        <div className="p-2 h-2/5 mb-4">
          <p className="text-lg md:text-2xl 2xl:text-3xl text-[#FFECC7]">
            {title}
          </p>
          <p className="text-sm md:text-base 2xl:text-lg text-[#F2C14B] break-words line-clamp-3">
            {location}
            <span className="text-[#3EB649]"> {date}</span>
          </p>
          {isAdmin() && (
            <span className="text-white capitalize"> {status}</span>
          )}
        </div>
      </div>
    </Link>
  );
};

export default EventCard;
