import Copyright from '../components/Copyright';
import SectionTitle from '../components/common/SectionTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Key, useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../redux/store';
import { fetchEventDetail } from '../redux/actions/fetchEventDetail';
import formatDateRange from '../utilities/formatDateRange';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { joinEvent } from '../redux/actions/joinEvent';
import {
  fetchEventParticipants,
  resetEventParticipants,
} from '../redux/actions/fetchEventParticipantsActions';
import L, { LatLngExpression } from 'leaflet';
import { deleteEvent } from '../redux/actions/fetchUpcomingEvents';
import isAdmin from '../utilities/getRole';

function EventDetail(this: any) {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const {
    loading,
    data: detail,
    error,
  } = useSelector((state: any) => state.eventDetail);
  const participants = useSelector(
    (state: RootState) => state.participants.data?.data || [],
  );

  const pinIcon = L.icon({
    iconUrl: '/assets/icons/pin-location.png',
    iconSize: [40, 40],
  });

  const extractLatLng = (url: string): [number, number] | null => {
    const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = url.match(regex);
    return match ? [parseFloat(match[1]), parseFloat(match[2])] : null;
  };

  const position: LatLngExpression = detail?.location_url
    ? extractLatLng(detail.location_url) || [34.6859601, 135.4415912]
    : [34.6859601, 135.4415912];

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const totalParticipants = useSelector(
    (state: RootState) => state.participants.data?.total || 0,
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchEventDetail(id));
    }

    return () => {
      dispatch(resetEventParticipants());
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(fetchEventParticipants(id, currentPage, searchQuery));
    }
  }, [dispatch, id, currentPage, searchQuery]);

  const loadMoreParticipants = () => {
    if (participants.length < totalParticipants) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleJoinEvent = async () => {
    if (id) {
      await dispatch(joinEvent(Number(detail?.id)));
    }
    window.location.reload();
  };

  const handleSearch = () => {
    setCurrentPage(1);
    if (id) {
      dispatch(fetchEventParticipants(id, currentPage, searchQuery, true));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const navigate = useNavigate();

  const handleEditEvent = () => {
    navigate(`/event/edit/${detail.id}`);
  };

  const handleDeleteEvent = async () => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      if (id !== undefined) {
        await dispatch(deleteEvent(detail.id));
      } else {
        console.error('Event ID is undefined');
      }

      navigate('/events');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="bg-[#DDE4E4] min-h-screen">
      {isAdmin() && (
        <div className="mx-4 lg:container lg:mx-auto flex gap-2 py-8 justify-end">
          <button
            onClick={handleEditEvent}
            className="bg-yellow-500 text-white py-2 px-4 rounded-xl hover:bg-yellow-600 transition"
          >
            Edit Event
          </button>
          <button
            onClick={handleDeleteEvent}
            className="bg-red-500 text-white py-2 px-4 rounded-xl hover:bg-red-600 transition"
          >
            Delete Event
          </button>
        </div>
      )}

      <div className="mx-4 lg:container lg:mx-auto flex flex-col lg:flex-row gap-8 py-8">
        <div className="lg:w-1/2">
          {/* Left Section */}
          <SectionTitle title="Detail" titleBadge="events-logo" />
          <div className="bg-[#E8FAEE] shadow-lg rounded-2xl p-4 lg:p-6 fredoka-600">
            <div className="flex flex-col lg:flex-row">
              <img
                src={`https://inidevloh.coppamagz.com/${detail?.image}`}
                alt="Event"
                className="rounded-xl mb-4 w-full md:w-4/5 lg:w-3/5 h-[375px] object-cover"
                draggable="false"
              />
              <div>
                <div className="bg-primary-50 lg:ml-4 rounded-lg space-y-2">
                  <h3 className="text-2xl lg:text-3xl text-pink-500">
                    {detail?.title}
                  </h3>
                  <p className="text-lg lg:text-xl text-primary-600">
                    {detail?.location}
                  </p>
                  <p className="text-lg lg:text-xl text-primary-600">
                    {formatDateRange(detail?.start_time, detail?.end_time)}
                  </p>
                  <p className="text-lg lg:text-xl text-primary-600">
                    Maximum {detail?.max_participants || '∞'} People
                  </p>
                  <p className="text-xl text-primary-600">
                    {detail?.confirmed_participants_count} People join
                  </p>
                </div>
              </div>
            </div>
            <p className="mt-4 text-xl fredoka-600 text-primary-600">
              {detail?.description}
            </p>
            <button
              onClick={handleJoinEvent}
              className="mt-6 ml-auto block bg-primary-600 text-white py-2 px-4 rounded-xl hover:bg-primary-700 transition"
            >
              JOIN EVENT
            </button>
          </div>

          {/* Map Section */}
          <div className="mt-10">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-3xl fredoka-600 text-gray-700">
                Peta lokasi
              </h2>
              {detail?.location_url && (
                <a
                  target="_"
                  href={detail.location_url}
                  className="mt-4 bg-white font-semibold text-primary-550 border-1 border-primary-600 py-2 px-6 rounded-xl hover:bg-gray-100 transition"
                >
                  Open Map
                </a>
              )}
            </div>
            <div className="bg-primary-600 rounded-2xl p-4 relative">
              <MapContainer
                center={position}
                zoom={13}
                scrollWheelZoom={false}
                className="h-80 w-full rounded-lg z-0"
                style={{ height: '320px', width: '100%' }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {detail?.location_url && (
                  <Marker position={position} icon={pinIcon}>
                    <Popup>{detail?.location || 'Event Location'}</Popup>
                  </Marker>
                )}
              </MapContainer>
              <img
                src="/assets/girl-boy.png"
                alt="decorations"
                className="absolute bottom-0 right-0 w-56 z-10"
                draggable="false"
              />
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="lg:w-1/2">
          <SectionTitle title="Attendee" titleBadge="events-logo" />

          <div>
            <div className="flex gap-2 items-center w-full lg:w-3/5">
              <input
                type="text"
                placeholder="Search for Attendee..."
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                className="p-3 rounded-xl text-xs border-1 border-primary-600 w-60 focus:outline-none flex-grow"
              />
              <button
                className="bg-primary-600 text-white p-2 rounded-xl hover:bg-primary-700 transition"
                onClick={handleSearch}
              >
                <img
                  src="/assets/icons/search.png"
                  alt="search button"
                  className="h-5"
                />
              </button>
            </div>
            <p className="text-[#808080] my-4">
              {detail?.confirmed_participants_count} Attendee
            </p>
            <div className="grid grid-cols-4 md:grid-cols-8 lg:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-9 gap-2">
              {/* Example attendee */}
              {participants.map(
                (
                  participant: {
                    status: string;
                    id: Key | null | undefined;
                    user: {
                      profile_picture_url: any;
                      name: string | undefined;
                    };
                  },
                  index: any,
                ) => (
                  <div
                    key={index}
                    className={`relative rounded-xl overflow-hidden hover:bg-[#3EB64970] transition ${
                      participant.status === 'booked'
                        ? 'opacity-30'
                        : 'opacity-100'
                    }`}
                  >
                    <img
                      key={participant.id}
                      src={
                        participant.user.profile_picture_url
                          ? `https://inidevloh.coppamagz.com/${participant.user.profile_picture_url}`
                          : 'https://placehold.co/320x320/png'
                      }
                      alt={participant.user.name}
                      className="w-20 h-20 object-cover"
                    />
                    <span className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-[#3EB64970] opacity-0 hover:opacity-100 transition">
                      {participant.user.name}
                    </span>
                  </div>
                ),
              )}
            </div>
            <button
              onClick={loadMoreParticipants}
              disabled={participants.length >= totalParticipants}
              className={`mt-6 mx-auto block fredoka-600 bg-primary-600 text-white py-2 px-4 rounded-xl 
    hover:bg-primary-700 transition ${participants.length >= totalParticipants ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              See More
            </button>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Copyright />
    </div>
  );
}

export default EventDetail;
