import { AppDispatch } from '../store';
import http from '../services/httpService';
import { toast } from 'react-toastify';

export const fetchAnnouncements = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'FETCH_ANNOUNCEMENTS_REQUEST' });

  try {
    const response = await http.get('/announcement?isPaginate=0');

    if (response.data.success) {
      dispatch({
        type: 'FETCH_ANNOUNCEMENTS_SUCCESS',
        payload: response.data.data,
      });
    }
  } catch (error: any) {
    let errorMessage = 'Failed to fetch announcements.';

    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    }

    dispatch({
      type: 'FETCH_ANNOUNCEMENTS_FAILURE',
      payload: errorMessage,
    });

    toast.error(errorMessage);
  }
};

export const fetchLatestAnnouncements = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'FETCH_LATEST_ANNOUNCEMENTS_LOADING' });

  try {
    const response = await http.get(
      '/announcement?isPaginate=1&perPage=5&page=1',
    );

    if (response.data.success) {
      dispatch({
        type: 'FETCH_LATEST_ANNOUNCEMENTS_SUCCESS',
        payload: response.data.data.data, // Extract the nested data
      });
    }
  } catch (error: any) {
    let errorMessage = 'Failed to fetch latest announcements.';

    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    }

    dispatch({
      type: 'FETCH_LATEST_ANNOUNCEMENTS_ERROR',
      error: errorMessage,
    });

    toast.error(errorMessage);
  }
};

export const createAnnouncement =
  (formData: any) => async (dispatch: AppDispatch) => {
    dispatch({ type: 'CREATE_ANNOUNCEMENT_REQUEST' });

    try {
      const formDataWithImages = new FormData();
      formDataWithImages.append('title', formData.title);
      formDataWithImages.append('event_id', formData.eventId);
      formDataWithImages.append('content', formData.content);
      formDataWithImages.append('end_date', formData.endDate);
      formDataWithImages.append('status', formData.status);
      formDataWithImages.append('release_date', formData.releaseDate);

      if (formData.image) {
        formDataWithImages.append('image', formData.image);
      }

      if (formData.image2) {
        formDataWithImages.append('image2', formData.image2);
      }

      const response = await http.post(
        '/admin/announcement/create',
        formDataWithImages,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      if (response.data.success) {
        const successAction = {
          type: 'CREATE_ANNOUNCEMENT_SUCCESS',
          payload: response.data.data,
        };

        dispatch(successAction);

        toast.success('Announcement created successfully', {
          autoClose: 3000,
          pauseOnHover: false,
          onClose: () => {
            dispatch({ type: 'SIGNUP_LOADING_FINISHED' });
            window.location.reload();
          },
        });

        return successAction; // <-- Return the action object
      }
    } catch (error: any) {
      let errorMessage = 'Failed to create announcement.';

      if (error.response && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      const failureAction = {
        type: 'CREATE_ANNOUNCEMENT_FAILURE',
        payload: errorMessage,
      };

      dispatch(failureAction);
      toast.error(errorMessage);

      return failureAction; // <-- Return the failure action
    }
  };
