import { AppDispatch } from '../store';
import http from '../services/httpService';
import { toast } from 'react-toastify';

export const checkAvailability =
  (formData: { phone_number: string; email: string }) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: 'CHECK_AVAILABILITY_REQUEST' });

    try {
      const queryParams = new URLSearchParams(formData).toString();
      const url = `/check-availability?${queryParams}`;
      const response = await http.get(url);

      if (response.data.success) {
        dispatch({
          type: 'CHECK_AVAILABILITY_SUCCESS',
          payload: response.data.message,
        });

        return { success: true };
      } else {
        const { phone_number, email } = response.data.message;

        dispatch({
          type: 'CHECK_AVAILABILITY_FAILURE',
          payload: {
            phone_number: phone_number ? phone_number.join(', ') : undefined,
            email: email ? email.join(', ') : undefined,
          },
        });

        return { success: false, error: response.data.message };
      }
    } catch (error: any) {
      let errorMessage = 'An unexpected error occurred. Please try again.';

      if (error.response && error.response.status === 422) {
        const { message } = error.response.data;
        dispatch({
          type: 'CHECK_AVAILABILITY_FAILURE',
          payload: {
            phone_number: message.phone_number?.join(', '),
            email: message.email?.join(', '),
          },
        });

        return { success: false, error: message };
      } else {
        toast.error(errorMessage);
        console.error('Error checking availability:', error);
        dispatch({ type: 'CHECK_AVAILABILITY_ERROR' });
        return { success: false, error: errorMessage };
      }
    }
  };
