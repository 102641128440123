import { toast } from 'react-toastify';
import http from '../services/httpService';
import { AppDispatch } from '../store';

export const getUserBadge = () => async (dispatch: AppDispatch) => {
  const authToken = localStorage.getItem('authToken');
  if (!authToken) {
    toast.error('No auth token found');
    return;
  }

  try {
    const response = await http.get('/profile/user-badge', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (response.data.success) {
      dispatch({
        type: 'SET_USER_BADGE',
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: 'SET_USER_BADGE',
        payload: [],
      });
    }
  } catch (error) {
    toast.error('Failed to fetch user badge');
    console.error('Error fetching user badge:', error);
  }
};

export const redeemBadge =
  (badgeId: number) => async (dispatch: AppDispatch) => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        toast.error('No auth token found');
        return;
      }

      const response = await http.post(
        `/profile/badges/${badgeId}/redeem`,
        null,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      if (response.data.success) {
        dispatch({
          type: 'REDEEM_BADGE',
          payload: badgeId,
        });
        toast.success('Badge redeemed successfully!');
      } else {
        toast.error('Failed to redeem badge');
      }
    } catch (error) {
      toast.error('Error redeeming badge');
      console.error('Redeem badge error:', error);
    }
  };
