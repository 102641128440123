interface AnnouncementState {
  loading: boolean;
  data: any[] | null;
  latestAnnouncements: any[] | null;
  error: string | null;
}

const initialState = {
  data: [],
  isLoading: false,
  latestAnnouncements: [],
  error: null,
};

const announcementsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCH_ANNOUNCEMENTS_SUCCESS':
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case 'FETCH_ANNOUNCEMENTS_LOADING':
      return {
        ...state,
        isLoading: true,
      };
    case 'FETCH_ANNOUNCEMENTS_ERROR':
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case 'FETCH_LATEST_ANNOUNCEMENTS_SUCCESS':
      return {
        ...state,
        latestAnnouncements: action.payload,
        isLoading: false,
      };
    case 'FETCH_LATEST_ANNOUNCEMENTS_LOADING':
      return {
        ...state,
        isLoading: true,
      };
    case 'FETCH_LATEST_ANNOUNCEMENTS_ERROR':
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case 'CREATE_ANNOUNCEMENT_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'CREATE_ANNOUNCEMENT_SUCCESS':
      return {
        ...state,
        data: [action.payload, ...state.data],
        isLoading: false,
      };
    case 'CREATE_ANNOUNCEMENT_FAILURE':
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default announcementsReducer;
