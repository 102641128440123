import { AppDispatch } from '../store';
import http from '../services/httpService';
import { toast } from 'react-toastify';

export const fetchFandoms = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'FETCH_FANDOMS_REQUEST' });

  try {
    const response = await http.get('/list-fandom');

    if (response.data.success) {
      dispatch({
        type: 'FETCH_FANDOMS_SUCCESS',
        payload: response.data.data,
      });
    }
  } catch (error: any) {
    let errorMessage = 'Failed to fetch fandoms.';

    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    }

    dispatch({
      type: 'FETCH_FANDOMS_FAILURE',
      payload: errorMessage,
    });

    toast.error(errorMessage);
  }
};
