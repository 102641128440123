import React from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

type SelectFieldProps = {
  register: UseFormRegisterReturn;
  options: { value: string; label: string }[];
  placeholder?: string;
  error?: FieldError;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
};

const SelectField: React.FC<SelectFieldProps> = ({
  register,
  options,
  placeholder,
  error,
  onChange,
  value,
}) => (
  <div>
    <select
      {...register}
      value={value}
      className={`w-full bg-gray-200 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600 ${
        value && value !== '' ? 'text-black' : 'text-gray-400'
      }`}
      onChange={(e) => {
        onChange && onChange(e);
      }}
    >
      {placeholder && <option value="">{placeholder}</option>}
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    {error && (
      <div className="text-red-500 flex flex-start text-left text-sm mt-1">
        {error.message}
      </div>
    )}
  </div>
);

export default SelectField;
