import { AppDispatch } from '../store';
import http from '../services/httpService';
import { toast } from 'react-toastify';

export const getUserProfile = () => async (dispatch: AppDispatch) => {
  dispatch({ type: 'USER_PROFILE_REQUEST' });

  try {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) throw new Error('No auth token found');

    const response = await http.get('/me', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    dispatch({
      type: 'USER_PROFILE_SUCCESS',
      payload: response.data.data,
    });
  } catch (error: any) {
    let errorMessage = 'Failed to fetch user profile.';

    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    }

    dispatch({
      type: 'USER_PROFILE_FAILURE',
      payload: errorMessage,
    });

    toast.error(errorMessage);
  }
};
