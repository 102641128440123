interface BadgeProps {
  badge: {
    badge_id: number;
    earned_at: string;
    badge: {
      title: string;
      icon: string;
      description: string;
      point: number;
    };
  };
  status: string;
}

const Badge: React.FC<BadgeProps> = ({ badge, status }) => {
  return (
    <div
      key={badge.badge_id}
      className="bg-white rounded-[24px] shadow-xl overflow-hidden flex flex-col text-left md:last-of-type:flex last-of-type:hidden"
    >
      <div className="w-full relative">
        {/* Badge Icon */}
        <div className="flex justify-center w-full h-40 relative z-0">
          {badge.badge.icon ? (
            <img
              src={`https://inidevloh.coppamagz.com/${badge.badge.icon}`}
              alt={badge.badge.title}
              className="object-contain bg-gray-200 pt-4 rounded-md w-full h-full aspect-[calc(1)/1]"
            />
          ) : (
            <img
              src="./logo-illustration.png"
              alt="default badge icon"
              className="object-contain bg-gray-200 pt-4 rounded-md w-full h-full aspect-[calc(1)/1]"
            />
          )}
        </div>
      </div>

      <div className="flex flex-col gap-0 py-4 px-4 w-full relative">
        {/* Badge Title */}
        <p className="text-lg font-bold uppercase mt-1 fredoka-650 text-center w-full">
          {badge.badge.title}
        </p>

        {/* Points */}
        <p className="text-sm text-center mt-1 fredoka-550">
          POINTS +{badge.badge.point}
        </p>

        {/* Earned Date */}
        <p className="text-sm text-center mt-1 fredoka-550">
          {new Date(badge.earned_at).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          })}
        </p>
      </div>
    </div>
  );
};

export default Badge;
