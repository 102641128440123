import { AppDispatch } from '../store';
import http from '../services/httpService';
import { toast } from 'react-toastify';

export const fetchEventDetail =
  (id: string) => async (dispatch: AppDispatch) => {
    dispatch({ type: 'FETCH_EVENT_DETAIL_REQUEST' });

    try {
      const response = await http.get(`/event/${id}`);

      if (response.data.success) {
        dispatch({
          type: 'FETCH_EVENT_DETAIL_SUCCESS',
          payload: response.data.data,
        });
      }
    } catch (error: any) {
      let errorMessage = 'Failed to fetch event details.';

      if (error.response && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      dispatch({
        type: 'FETCH_EVENT_DETAIL_FAILURE',
        payload: errorMessage,
      });

      toast.error(errorMessage);
    }
  };
