import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { fetchUpcomingEvents } from '../../redux/actions/fetchUpcomingEvents';
import InfiniteScroll from 'react-infinite-scroll-component';
import EventCard from './EventCard';
import SectionTitle from '../common/SectionTitle';
import EventFormModal from './EventFormModal';
import isAdmin from '../../utilities/getRole';

const UpcomingEvents = () => {
  const dispatch: AppDispatch = useDispatch();
  const { events, pagination, loading, error } = useSelector(
    (state: RootState) => state.upcomingEvents,
  );
  const [hasMore, setHasMore] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState('');

  useEffect(() => {
    setStatus(isAdmin() ? '' : 'published');

    return () => {
      setStatus('published');
    };
  }, []);

  useEffect(() => {
    dispatch(fetchUpcomingEvents(1, 8, '', status));
  }, [dispatch]);

  useEffect(() => {
    if (pagination) {
      setHasMore(pagination.currentPage < pagination.lastPage);
    }
  }, [pagination]);

  const fetchNextPage = async () => {
    let url = pagination?.nextPage;

    if (!url || loading) return;

    const urlObj = new URL(url);

    const page = urlObj.searchParams.get('page');

    if (page) {
      await dispatch(fetchUpcomingEvents(Number(page), 8, '', status, false));
    }
  };

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  if (loading && events.length === 0) return <p>Loading events...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="fredoka-600">
      <SectionTitle
        title="Upcoming"
        titleBadge="events-logo"
        buttonTitle="Event"
        onButtonClick={toggleModal}
      />
      {events.length ? (
        <InfiniteScroll
          dataLength={events.length}
          next={fetchNextPage}
          hasMore={hasMore}
          loader={<h4>Loading more events...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {events.map((event: any, index) => (
              <EventCard
                key={index}
                imgSrc={`https://inidevloh.coppamagz.com/${event.image}`}
                title={event.title}
                location={event.location}
                date={new Date(event.start_time).toLocaleDateString()}
                attendance={event.max_participants || '-'}
                slug={event.slug || '#'}
                status={event.status}
              />
            ))}
          </div>
        </InfiniteScroll>
      ) : (
        <p className="text-center">
          <b>No events available</b>
        </p>
      )}

      <EventFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default UpcomingEvents;
