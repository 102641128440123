import React from 'react';

interface ProfilePictureProps {
  profilePictureUrl: string | null;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  profilePictureUrl,
}) => {
  const profileImageUrl = profilePictureUrl
    ? `https://inidevloh.coppamagz.com/${profilePictureUrl}`
    : 'https://placehold.co/150x150/png';

  return (
    <div className="w-full h-full border border-3 border-primary-550 rounded-full overflow-hidden">
      <img
        src={profileImageUrl}
        alt="Profile"
        className="w-full h-full object-cover"
        draggable="false"
      />
    </div>
  );
};

export default ProfilePicture;
