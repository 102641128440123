interface Badge {
  badge_id: number;
  earned_at: string;
  badge: {
    title: string;
    icon: string;
    description: string;
    point: number;
  };
}

interface BadgeState {
  badges: Badge[];
}

const initialState: BadgeState = {
  badges: [],
};

export const badgeReducer = (state = initialState, action: any): BadgeState => {
  switch (action.type) {
    case 'SET_USER_BADGE':
      return {
        ...state,
        badges: action.payload,
      };

    case 'REDEEM_BADGE':
      return {
        ...state,
      };

    default:
      return state;
  }
};
