import TextField from '../common/Fields/TextField';
import PasswordField from '../common/Fields/PasswordField';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ForgotPasswordSchema,
  FormFields,
} from '../../schemas/ForgotPasswordSchema';
import { forgotPassword } from '../../redux/actions/forgotPasswordActions';
import { sendToken } from '../../redux/actions/forgotPasswordActions';
import { useEffect, useState } from 'react';

const ForgotPasswordForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { loading, error } = useSelector(
    (state: RootState) => state.forgotPassword,
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<FormFields>({
    defaultValues: {
      email: '',
      token: '',
      password: '',
      password_confirmation: '',
    },
    resolver: zodResolver(ForgotPasswordSchema),
  });

  const onSubmit = (data: FormFields) => {
    dispatch(
      forgotPassword(
        {
          email: data.email,
          token: data.token,
          password: data.password,
          password_confirmation: data.password_confirmation,
        },
        navigate,
      ),
    );

    reset();
  };

  const [countdown, setCountdown] = useState<number | null>(null);
  const [canRequestToken, setCanRequestToken] = useState(true);

  const handleSendToken = async () => {
    const response = await dispatch(sendToken({ email: watch('email') }));

    if (response?.success) {
      setCanRequestToken(false);
      setCountdown(60);
    }
  };

  useEffect(() => {
    if (countdown == 0) {
      setCanRequestToken(true);
    }
  }, [countdown]);

  useEffect(() => {
    if (countdown === null || countdown <= 0) return;

    const timer = setInterval(() => {
      setCountdown((prev: any) => (prev !== null ? prev - 1 : null));
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown]);

  const formatCountdown = () => {
    if (countdown === null) return '';
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ${seconds} second${seconds !== 1 ? 's' : ''}`;
  };

  const isTokenWaitMessage =
    error === 'Wait for another 30 seconds to request Token' ||
    countdown !== null;

  return (
    <>
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        {error && (
          <div className="text-red-500 flex justify-center text-sm mt-2">
            {error}
          </div>
        )}

        <TextField
          register={register('email')}
          placeholder="Email"
          type="email"
          error={errors.email}
        />
        {!canRequestToken ? (
          <p className="text-gray-500 text-center text-sm">
            {countdown !== null
              ? `Wait for ${formatCountdown()} to request Token`
              : 'Wait for another 1 minute to request Token'}
          </p>
        ) : (
          <button
            type="button"
            className="text-primary-600 font-semibold text-sm"
            onClick={handleSendToken}
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Send Token'}
          </button>
        )}

        <TextField
          register={register('token')}
          placeholder="Token"
          error={errors.token}
        />
        <PasswordField
          register={register('password')}
          placeholder="New Password"
          error={errors.password}
        />
        <PasswordField
          register={register('password_confirmation')}
          placeholder="Confirm Password"
          error={errors.password_confirmation}
        />
        <button
          type="submit"
          disabled={loading}
          className="w-full border border-transparent bg-primary-600 text-white text-lg py-2 rounded-md hover:bg-primary-700 transition disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          {loading ? 'Forgetting password...' : 'Let Me In!'}
        </button>
        {errors.root && (
          <div className="text-red-500 flex flex-start text-left text-sm mt-2">
            {errors.root.message}
          </div>
        )}
      </form>
    </>
  );
};

export default ForgotPasswordForm;
