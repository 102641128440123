import { format } from 'date-fns';
import { id as idLocale } from 'date-fns/locale';

const formatEventDateRange = (start: string, end: string) => {
  try {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const startDay = format(startDate, 'EEEE, dd MMMM', { locale: idLocale });
    const startTime = format(startDate, "HH.mm 'WIB'", { locale: idLocale });

    const endDay = format(endDate, 'EEEE, dd MMMM', { locale: idLocale });
    const endTime = format(endDate, "HH.mm 'WIB'", { locale: idLocale });

    if (startDay === endDay) {
      // Same day: Combine start and end times
      return `${startDay}, ${startTime} - ${endTime}`;
    } else {
      // Different days: Show full range
      return `${startDay}, ${startTime} - ${endDay}, ${endTime}`;
    }
  } catch (error) {
    // console.error('Invalid date format:', error);
    return 'Invalid date';
  }
};

export default formatEventDateRange;
