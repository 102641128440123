import React from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

type ErrorType = FieldError | { message?: string };

interface TextFieldProps {
  register: UseFormRegisterReturn;
  placeholder: string;
  type?: string;
  error?: ErrorType;
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextField: React.FC<TextFieldProps> = ({
  register,
  type = 'text',
  placeholder,
  error,
  onInput,
}) => (
  <div>
    <input
      {...register}
      type={type}
      placeholder={placeholder}
      className="w-full bg-gray-200 placeholder:text-gray-400 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600"
      onInput={onInput}
    />
    {error && (
      <div className="text-red-500 flex flex-start text-left text-sm mt-1">
        {error.message}
      </div>
    )}
  </div>
);

export default TextField;
