import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { SignUpSchema, FormFields } from '../../schemas/SignUpSchema';
import TermsOfUseForm from './TermsOfUseForm';
import { toast } from 'react-toastify';
import TextField from '../common/Fields/TextField';
import SelectField from '../common/Fields/SelectField';
import PasswordField from '../common/Fields/PasswordField';
import { AppDispatch } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCities, fetchProvinces } from '../../services/locationService';
import { checkAvailability } from '../../redux/actions/checkAvailabilityActions';

const SignUpForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const serverErrors = useSelector(
    (state: any) => state.checkAvailability.errors,
  );

  const [showTerms, setShowTerms] = useState(false);
  const [provinces, setProvinces] = useState<{ code: string; name: string }[]>(
    [],
  );
  const [cities, setCities] = useState<{ code: string; name: string }[]>([]);
  const [_, setSelectedProvince] = useState<string | ''>('');

  const {
    register,
    trigger,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<FormFields>({
    defaultValues: {
      name: '',
      phone_number: '',
      date_of_birth: '',
      gender: undefined,
      city: '',
      province: '',
      email: '',
      password: '',
      password_confirmation: '',
    },
    resolver: zodResolver(SignUpSchema),
  });

  // Fetch provinces
  useEffect(() => {
    const getProvinces = async () => {
      try {
        const provinces = await fetchProvinces();
        setProvinces(provinces);
      } catch (error) {
        console.error('Error fetching provinces:', error);
      }
    };

    getProvinces();
  }, []);

  const handleGenderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    register('gender').onChange(e);
  };

  // Fetch cities when province is selected
  const handleProvinceChange = async (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const provinceName = e.target.value;

    // Update the selected province state
    setSelectedProvince(provinceName);

    // Update the form's province field
    register('province').onChange(e);

    // Clear cities and reset the city field
    setCities([]);
    register('city').onChange({ target: { name: 'city', value: '' } });

    try {
      // Find the province code for the selected province
      const provinceCode = provinces.find(
        (province) => province.name === provinceName,
      )?.code;

      if (provinceCode) {
        // Fetch and set the cities for the selected province
        const cities = await fetchCities(provinceCode);
        setCities(cities);
      }
    } catch (error) {
      console.error('Error fetching cities:', error);
      setCities([]);
    }
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    register('city').onChange(e);
  };

  async function validateSignUp(data: any) {
    const result = await SignUpSchema.safeParseAsync(data);
    if (!result.success) {
      console.log(result.error.format());
      return result.error.format();
    }
    return null;
  }

  const handleLetMeInClick = async () => {
    const isValid = await trigger();
    if (!isValid) return;

    const formData = {
      name: watch('name'),
      phone_number: watch('phone_number'),
      date_of_birth: watch('date_of_birth'),
      gender: watch('gender'),
      province: watch('province'),
      city: watch('city'),
      email: watch('email'),
      password: watch('password'),
      password_confirmation: watch('password_confirmation'),
    };

    const validationErrors = await validateSignUp(formData);
    if (validationErrors) {
      Object.entries(validationErrors).forEach(([key, error]) => {
        if (error && '_errors' in error) {
          toast.error(error._errors[0] || 'Invalid input');
        }
      });

      return;
    }

    const availabilityCheck = await dispatch(
      checkAvailability({
        phone_number: formData.phone_number,
        email: formData.email,
      }),
    );

    if (availabilityCheck.success) {
      setShowTerms(true);
    } else {
      toast.error('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <>
      {showTerms ? (
        <TermsOfUseForm data={watch()} />
      ) : (
        <form className="flex flex-col gap-4">
          <TextField
            register={register('name')}
            placeholder="Nama Lengkap"
            error={errors.name}
          />
          <TextField
            register={register('phone_number')}
            placeholder="No Handphone"
            error={
              errors.phone_number || { message: serverErrors.phone_number }
            }
            onInput={(e) => {
              const input = e.target as HTMLInputElement;
              input.value = input.value.replace(/[^0-9]/g, '');
            }}
          />
          <TextField
            register={register('date_of_birth')}
            placeholder="Tanggal Lahir"
            type="date"
            error={errors.date_of_birth}
          />
          <SelectField
            register={register('gender')}
            onChange={handleGenderChange}
            value={watch('gender')}
            placeholder="Jenis Kelamin"
            options={[
              { value: 'male', label: 'Laki-laki' },
              { value: 'female', label: 'Perempuan' },
            ]}
            error={errors.gender}
          />
          <SelectField
            register={register('province')}
            onChange={handleProvinceChange}
            value={watch('province')}
            placeholder="Pilih Provinsi"
            options={provinces.map((province) => ({
              value: province.name,
              label: province.name,
            }))}
            error={errors.province}
          />

          {watch('province') && (
            <SelectField
              register={register('city')}
              onChange={handleCityChange}
              value={watch('city')}
              placeholder="Pilih Kota"
              options={cities.map((city) => ({
                value: city.name,
                label: city.name,
              }))}
              error={errors.city}
            />
          )}

          <TextField
            register={register('email')}
            placeholder="Email"
            type="email"
            error={errors.email || { message: serverErrors.email }}
          />
          <PasswordField
            register={register('password')}
            placeholder="Password"
            error={errors.password}
          />
          <PasswordField
            register={register('password_confirmation')}
            placeholder="Confirm Password"
            error={errors.password_confirmation}
          />
          <button
            type="button"
            disabled={isSubmitting}
            onClick={handleLetMeInClick}
            className="w-full border border-transparent bg-primary-600 text-white text-lg py-2 rounded-md hover:bg-primary-700 transition disabled:bg-gray-400 disabled:cursor-not-allowed"
          >
            {isSubmitting ? 'Signing Up...' : 'Let Me In!'}
          </button>
          {errors.root && (
            <div className="text-red-500 flex flex-start text-left text-sm mt-2">
              {errors.root.message}
            </div>
          )}
        </form>
      )}
    </>
  );
};

export default SignUpForm;
