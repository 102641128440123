import React, { useEffect, useState } from 'react';
import getCurrentWIBTime from '../../utilities/getWIBTime';
import { useDispatch, useSelector } from 'react-redux';
import { createEvent } from '../../redux/actions/fetchUpcomingEvents';
import { AppDispatch, RootState } from '../../redux/store';

interface EventFormModalProps {
  isOpen: boolean;
  onClose: () => void;
}

type EventFormData = {
  title: string;
  description: string;
  image: File | null;
  locationUrl: string;
  startTime: string;
  endTime: string;
  type: 'organizer' | 'guest';
  organizer?: string;
  maxParticipants: string;
  status: 'draft' | 'published';
  location: string;
};

const EventFormModal: React.FC<EventFormModalProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch<AppDispatch>();

  // Access the events state from Redux
  // const { events, pagination, loading, error } = useSelector(
  //   (state: RootState) => state.upcomingEvents,
  // );
  // console.log(events);

  // Handle form data state
  const [formData, setFormData] = useState<EventFormData>({
    title: '',
    description: '',
    image: null as File | null,
    locationUrl: '',
    startTime: '',
    endTime: '',
    type: 'organizer',
    organizer: '',
    maxParticipants: '',
    status: 'draft',
    location: '',
  });

  // Updated handleChange function to handle input, textarea, and select elements
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFormData({ ...formData, image: e.target.files[0] });
    }
  };

  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Create a copy of formData without 'organizer' if type is 'organizer'
    const eventData = { ...formData };
    if (eventData.type === 'organizer') {
      delete eventData.organizer;
    }

    const result = await dispatch(createEvent(eventData));

    if (result?.type === 'CREATE_EVENT_SUCCESS') {
      setFormData({
        title: '',
        description: '',
        image: null as File | null,
        locationUrl: '',
        startTime: '',
        endTime: '',
        type: 'organizer',
        organizer: '',
        maxParticipants: '',
        status: 'draft',
        location: '',
      });
      setErrors({});
      onClose();
    } else if (result?.errors) {
      console.log('Errors received:', result.errors);

      if (Array.isArray(result.errors)) {
        const formattedErrors: { [key: string]: string[] } = {};
        result.errors.forEach((error, index) => {
          formattedErrors[`error_${index}`] = [error];
        });
        setErrors(formattedErrors);
      } else {
        setErrors(result.errors);
      }
    }
  };

  // Helper function to render error messages
  const renderError = (field: string) =>
    errors[field] && (
      <p className="text-red-500 text-sm mt-1">{errors[field].join(', ')}</p>
    );

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30 inter font-normal">
      <div className="bg-white p-6 rounded-lg shadow-lg w-2/3 h-4/5 overflow-y-scroll">
        <h2 className="text-xl font-semibold mb-4">Create Event</h2>

        {/* Form content */}
        <form onSubmit={handleSubmit}>
          {/* Title */}
          <div className="mb-4">
            <label className="block text-gray-700">Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="w-full p-2 border-1 border-gray-400 focus:outline focus:outline-primary-600 rounded"
              required
              maxLength={100}
            />
            {errors.title && (
              <p className="text-red-500 text-sm mt-1">{errors.title[0]}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full p-2 border-1 border-gray-400 focus:outline focus:outline-primary-600 rounded"
              required
            />
            {renderError('description')}
          </div>

          {/* Image */}
          <div className="mb-4">
            <label className="block text-gray-700">Image</label>
            <input
              type="file"
              name="image"
              onChange={handleFileChange}
              className="w-full p-2 border-1 border-gray-400 focus:outline focus:outline-primary-600 rounded"
              required
              accept="image/jpeg, image/png, image/jpg"
            />
            {renderError('image')}
          </div>

          {/* Location URL */}
          <div className="mb-4">
            <label className="block text-gray-700">Location URL</label>
            <input
              type="url"
              name="locationUrl"
              value={formData.locationUrl}
              onChange={handleChange}
              className="w-full p-2 border-1 border-gray-400 focus:outline focus:outline-primary-600 rounded"
              required
            />
            {renderError('location_url')}
          </div>

          {/* Start Time */}
          <div className="mb-4">
            <label className="block text-gray-700">Start Time</label>
            <input
              type="datetime-local"
              name="startTime"
              value={formData.startTime}
              onChange={handleChange}
              className="w-full p-2 border-1 border-gray-400 focus:outline focus:outline-primary-600 rounded"
              required
              min={getCurrentWIBTime()}
            />
            {renderError('start_time')}
          </div>

          {/* End Time */}
          <div className="mb-4">
            <label className="block text-gray-700">End Time</label>
            <input
              type="datetime-local"
              name="endTime"
              value={formData.endTime}
              onChange={handleChange}
              className="w-full p-2 border-1 border-gray-400 focus:outline focus:outline-primary-600 rounded"
              required
              min={formData.startTime}
            />
            {renderError('end_time')}
          </div>

          {/* Type */}
          <div className="mb-4">
            <label className="block text-gray-700">Type</label>
            <select
              name="type"
              value={formData.type}
              onChange={handleChange}
              className="w-full p-2 border-1 border-gray-400 focus:outline focus:outline-primary-600 rounded"
              required
            >
              <option value="organizer">Organizer</option>
              <option value="guest">Guest</option>
            </select>
          </div>

          {/* Organizer */}
          {formData.type === 'guest' && (
            <div className="mb-4">
              <label className="block text-gray-700">Organizer</label>
              <input
                type="text"
                name="organizer"
                value={formData.organizer}
                onChange={handleChange}
                className="w-full p-2 border-1 border-gray-400 focus:outline focus:outline-primary-600 rounded"
                required
                maxLength={100}
              />
              {renderError('organizer')}
            </div>
          )}

          {/* Max Participants */}
          <div className="mb-4">
            <label className="block text-gray-700">Max Participants</label>
            <input
              type="number"
              name="maxParticipants"
              value={formData.maxParticipants}
              onChange={handleChange}
              onWheel={(event) => event.currentTarget.blur()}
              className="w-full p-2 border-1 border-gray-400 focus:outline focus:outline-primary-600 rounded"
              min={1}
            />
            {renderError('max_participants')}
          </div>

          {/* Status */}
          <div className="mb-4">
            <label className="block text-gray-700">Status</label>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="w-full p-2 border-1 border-gray-400 focus:outline focus:outline-primary-600 rounded"
              required
            >
              <option value="draft">Draft</option>
              <option value="published">Published</option>
              <option value="archived">Archived</option>
            </select>
          </div>

          {/* Location */}
          <div className="mb-4">
            <label className="block text-gray-700">Location</label>
            <input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
              className="w-full p-2 border-1 border-gray-400 focus:outline focus:outline-primary-600 rounded"
              required
              maxLength={255}
            />
            {renderError('location')}
          </div>

          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-primary-600 text-white rounded hover:bg-primary-700"
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EventFormModal;
