import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProfilePicture from './profile/ProfilePicture';
import { useSelector } from 'react-redux';

const Header = () => {
  const location = useLocation();
  const isProfilePage = location.pathname === '/profile';
  const isAuthPage = location.pathname === '/auth';

  const navigate = useNavigate();

  const { profile: user } = useSelector((state: any) => state.user);

  // Custom Hook for handling logout
  const useLogout = (navigate: ReturnType<typeof useNavigate>) => {
    return async () => {
      const authToken = localStorage.getItem('authToken');

      if (!authToken) {
        return;
      }

      try {
        const response = await fetch(
          'https://inidevloh.coppamagz.com/testpingtestpin12345/api/logout',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`,
            },
          },
        );

        if (!response.ok) {
          throw new Error('Logout failed');
        }

        localStorage.removeItem('authToken');
        localStorage.removeItem('userRole');

        toast.success('Logout successful! Redirecting to login...', {
          autoClose: 3000,
          pauseOnHover: false,
          onClose: () => {
            navigate('/auth');
          },
        });
      } catch (error) {
        console.error('Logout error:', error);
      }
    };
  };

  const handleLogout = useLogout(navigate);

  if (isAuthPage) return null;

  return (
    <div className="sticky top-0 bg-white w-full p-2 z-50 shadow-lg">
      <div className="container flex justify-between mx-auto">
        <Link to="/events">
          <img src="/assets/logo.png" alt="logo" className="w-60" />
        </Link>
        <div className="flex gap-4 items-center">
          <Link to="/events" className="hover:text-green-600 transition">
            Home
          </Link>
          <Link to="/profile" className="hover:text-green-600 transition">
            Profile
          </Link>
          {isProfilePage ? (
            <button onClick={handleLogout} className="btn-primary">
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 hover:text-green-600 transition"
              >
                <path
                  d="M11 20H6a2 2 0 01-2-2V6a2 2 0 012-2h5M20 12l-4-4m4 4l-4 4m4-4H10"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          ) : (
            <Link to="/profile" className="w-14 h-14">
              <ProfilePicture profilePictureUrl={user?.profile_picture_url} />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
