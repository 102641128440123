import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authReducer';
import signUpReducer from './reducers/signUpReducer';
import fandomReducer from './reducers/fandomReducer';
import { userReducer } from './reducers/userReducer';
import { badgeReducer } from './reducers/badgeReducer';
import profileReducer from './reducers/profileReducer';
import checkAvailabilityReducer from './reducers/checkAvailabilityReducer';
import userEventsReducer from './reducers/userEventsReducer';
import announcementsReducer from './reducers/announcementsReducer';
import upcomingEventsReducer from './reducers/upcomingEventsReducer';
import eventDetailReducer from './reducers/eventDetailReducer';
import participantsReducer from './reducers/participantsReducer';
import eventsReducer from './reducers/eventsReducer';
import forgotPasswordReducer from './reducers/forgotPasswordReducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    signUp: signUpReducer,
    user: userReducer,
    badge: badgeReducer,
    profile: profileReducer,
    fandom: fandomReducer,
    checkAvailability: checkAvailabilityReducer,
    userEvents: userEventsReducer,
    upcomingEvents: upcomingEventsReducer,
    announcements: announcementsReducer,
    eventDetail: eventDetailReducer,
    participants: participantsReducer,
    events: eventsReducer,
    forgotPassword: forgotPasswordReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
