interface UserState {
  profile: any | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  profile: null,
  loading: false,
  error: null,
};

export const userReducer = (state = initialState, action: any): UserState => {
  switch (action.type) {
    case 'USER_PROFILE_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'USER_PROFILE_SUCCESS':
      return {
        ...state,
        loading: false,
        profile: action.payload,
      };
    case 'USER_PROFILE_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
