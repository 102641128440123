import { AppDispatch, RootState } from '../store';
import http from '../services/httpService';
import { toast } from 'react-toastify';

export const fetchEventParticipants =
  (eventId: string, page = 1, query: string = '', replace: boolean = false) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch({ type: 'FETCH_PARTICIPANTS_REQUEST' });

    try {
      const response = await http.get(
        `/event/${eventId}/participants?isPaginate=1&page=${page}&perPage=20&search=${query}`,
      );

      const existingParticipants = getState().participants.data?.data || [];

      if (response.data.success) {
        dispatch({
          type: 'FETCH_PARTICIPANTS_SUCCESS',
          payload: {
            ...response.data,
            data: replace
              ? response.data.data.data
              : [...existingParticipants, ...response.data.data.data],
            total: response.data.data.total,
          },
        });
      }
    } catch (error: any) {
      let errorMessage = 'Failed to fetch participants.';

      if (error.response && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      dispatch({
        type: 'FETCH_PARTICIPANTS_FAILURE',
        payload: errorMessage,
      });

      toast.error(errorMessage);
    }
  };

export const resetEventParticipants = () => ({
  type: 'RESET_PARTICIPANTS',
});
