import axios from 'axios';
import { toast } from 'react-toastify';
import { AppDispatch } from '../store';
import http from '../services/httpService';

export const updateProfile =
  (profileData: any, onClose: () => void, navigate: (path: string) => void) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: 'PROFILE_UPDATE_REQUEST' });

    try {
      const response = await http.put('/profile', profileData);

      if (response.status === 201) {
        dispatch({
          type: 'PROFILE_UPDATE_SUCCESS',
          payload: response.data.data,
        });

        toast.success('Profile updated successfully!', {
          autoClose: 3000,
          pauseOnHover: false,
          onClose: () => {
            onClose();
            navigate('/profile');
          },
        });
      }
    } catch (error) {
      let errorMessage = 'An unexpected error occurred.';

      if (axios.isAxiosError(error) && error.response) {
        // Handling validation errors (if status code is 422)
        if (error.response.data.status_code === 422) {
          errorMessage =
            error.response.data.data || 'There were validation errors.';
        } else {
          // Non-validation errors
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = Object.values(error.response.data.message).join(
              ', ',
            );
          }
        }
      }

      dispatch({
        type: 'PROFILE_UPDATE_FAILURE',
        payload: errorMessage, // Set a single error message
      });

      toast.error(errorMessage);
    }
  };

export const updateProfilePicture =
  (file: File) => async (dispatch: AppDispatch) => {
    dispatch({ type: 'PROFILE_PICTURE_UPDATE_REQUEST' });

    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await http.post('/profile/picture', formData);

      if (response.data.success) {
        dispatch({
          type: 'PROFILE_PICTURE_UPDATE_SUCCESS',
          payload: {
            path: response.data.data.path,
          },
        });
      } else {
        dispatch({
          type: 'PROFILE_PICTURE_UPDATE_FAILURE',
          payload: 'Failed to update profile picture.',
        });
      }
    } catch (error: any) {
      dispatch({
        type: 'PROFILE_PICTURE_UPDATE_FAILURE',
        payload: error.response?.data?.message || 'An error occurred.',
      });
    }
  };
