interface Event {
  id: number;
  event_id: number;
  registered_at: string;
  status: string;
  event: null | {
    id: number;
    title: string;
    slug: string;
    description: string;
    image: string;
    location: string;
    start_time: string;
    end_time: string;
    type: string;
    organizer: string;
    max_participants: number | null;
    status: string;
  };
}

interface Pagination {
  currentPage: number;
  lastPage: number;
  total: number;
  nextPage: string | null;
  prevPage: string | null;
}

interface UserEventsState {
  loading: boolean;
  events: Event[];
  pagination: Pagination | null;
  error: string | null;
}

const initialState: UserEventsState = {
  loading: false,
  events: [],
  pagination: null,
  error: null,
};

const upcomingEventsReducer = (
  state = initialState,
  action: any,
): UserEventsState => {
  switch (action.type) {
    case 'UPCOMING_EVENTS_REQUEST':
      return { ...state, loading: true, error: null };
    case 'UPCOMING_EVENTS_SUCCESS':
      return {
        ...state,
        loading: false,
        events: action.payload.replace
          ? action.payload.events
          : [...state.events, ...action.payload.events],
        pagination: action.payload.pagination,
      };
    case 'UPCOMING_EVENTS_FAILURE':
      return { ...state, loading: false, error: action.payload };

    // ✅ Create Event
    case 'CREATE_EVENT_REQUEST':
      return { ...state, loading: true };
    case 'CREATE_EVENT_SUCCESS':
      return {
        ...state,
        loading: false,
        events: [...state.events, action.payload],
      };
    case 'CREATE_EVENT_FAILURE':
      return { ...state, loading: false, error: action.payload };

    // ✅ Update Event
    case 'UPDATE_EVENT_REQUEST':
      return { ...state, loading: true };
    case 'UPDATE_EVENT_SUCCESS':
      return {
        ...state,
        loading: false,
        events: state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event,
        ),
      };
    case 'UPDATE_EVENT_FAILURE':
      return { ...state, loading: false, error: action.payload };

    // ✅ Delete Event
    case 'DELETE_EVENT_REQUEST':
      return { ...state, loading: true };
    case 'DELETE_EVENT_SUCCESS':
      return {
        ...state,
        loading: false,
        events: state.events.filter((event) => event.id !== action.payload),
      };
    case 'DELETE_EVENT_FAILURE':
      return { ...state, loading: false, error: action.payload };

    // ✅ Reset Events
    case 'RESET_UPCOMING_EVENTS':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default upcomingEventsReducer;
