interface CheckAvailabilityState {
  isLoading: boolean;
  errors: { phone_number?: string; email?: string };
  successMessage: any;
}

const initialState: CheckAvailabilityState = {
  isLoading: false,
  errors: {},
  successMessage: null,
};

const checkAvailabilityReducer = (
  state = initialState,
  action: any,
): CheckAvailabilityState => {
  switch (action.type) {
    case 'CHECK_AVAILABILITY_LOADING':
      return { ...state, isLoading: true };

    case 'CHECK_AVAILABILITY_SUCCESS':
      return {
        ...state,
        isLoading: false,
        successMessage: action.payload,
        errors: {},
      };

    case 'CHECK_AVAILABILITY_FAILURE':
      return { ...state, isLoading: false, errors: action.payload };

    case 'CHECK_AVAILABILITY_ERROR':
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

export default checkAvailabilityReducer;
