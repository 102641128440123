import React, { useEffect, useState } from 'react';
import EventBanner from '../components/events/EventBanner';
import LatestAnnouncements from '../components/events/LatestAnnouncements';
import UpcomingEvents from '../components/events/UpcomingEvents';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { getUserProfile } from '../redux/actions/userAction';
import Copyright from '../components/Copyright';
import { resetUpcomingEvents } from '../redux/actions/fetchUpcomingEvents';

const Events: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getUserProfile());
    return () => {
      dispatch(resetUpcomingEvents());
    };
  }, [dispatch]);

  const [searchValue, setSearchValue] = useState('');

  return (
    <div className="py-10">
      <div className="w-full flex items-center justify-end px-4 md:px-20 absolute z-20"></div>
      <div className="lg:container lg:mx-auto mx-4 p-0 md:p-6 py-10 space-y-12">
        <EventBanner setSearchValue={setSearchValue} />
        {!searchValue && <LatestAnnouncements />}
        <UpcomingEvents />
      </div>
      <Copyright />
    </div>
  );
};

export default Events;
