import { Reducer } from 'redux';

interface AuthState {
  loading: boolean;
  token: string | null;
  error: string | null;
}

const initialState: AuthState = {
  loading: false,
  token: null,
  error: null,
};

interface AuthAction {
  type:
    | 'AUTH_REQUEST'
    | 'AUTH_SUCCESS'
    | 'AUTH_FAILURE'
    | 'AUTH_LOADING_FINISHED';
  payload?: any;
}

const authReducer: Reducer<AuthState, AuthAction | { type: string }> = (
  state = initialState,
  action,
): AuthState => {
  switch (action.type) {
    case 'AUTH_REQUEST':
      return { ...state, loading: true, error: null };

    case 'AUTH_SUCCESS':
      if ('payload' in action) {
        return {
          ...state,
          token: action.payload.token,
          error: null,
        };
      }
      return state;

    case 'AUTH_FAILURE':
      if ('payload' in action) {
        return { ...state, loading: false, error: String(action.payload) };
      }
      return state;

    case 'AUTH_LOADING_FINISHED':
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default authReducer;
