import React, { useState } from 'react';
import Copyright from '../components/Copyright';
import ForgotPasswordForm from '../components/auth/ForgotPasswordForm';

const ForgotPasswordPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'signin' | 'signup'>('signin');

  return (
    <div className="flex flex-col justify-between items-center min-h-screen bg-gradient-to-br from-background-700 to-background-800 gap-12 pt-12 overflow-x-hidden">
      <div className="flex flex-col justify-center my-auto items-center w-full max-w-md">
        <img src="/assets/logo.png" alt="" className="h-20" />

        <div className="relative w-full">
          <div className="bg-white p-20 px-8 rounded-xl shadow-xl w-full relative z-10">
            <h1 className="text-2xl font-semibold text-center">
              Forgot Password
            </h1>
            <p className="text-center mb-4">Check your email to get Token</p>
            <ForgotPasswordForm />
          </div>
          {activeTab === 'signup' && (
            <img
              src="/assets/logo-illustration.png"
              alt="Logo"
              className="absolute w-32 mb-3 rotate-[55.63deg] top-0 -right-[105px] mt-24"
            />
          )}
        </div>
      </div>

      <Copyright />
    </div>
  );
};

export default ForgotPasswordPage;
