import React from 'react';

interface AuthTabsProps {
  activeTab: 'signin' | 'signup';
  onTabChange: (tab: 'signin' | 'signup') => void;
}

const AuthTabs: React.FC<AuthTabsProps> = ({ activeTab, onTabChange }) => {
  return (
    <div className="flex flex-col mb-6 border-b">
      <div className="flex">
        <button
          className={`flex-1 py-2 text-center text-lg font-semibold ${
            activeTab === 'signin' ? 'text-primary-600' : 'text-gray-150'
          }`}
          onClick={() => onTabChange('signin')}
        >
          Log In
        </button>
        <button
          className={`flex-1 py-2 text-center text-lg font-semibold ${
            activeTab === 'signup' ? 'text-primary-600' : 'text-gray-150'
          }`}
          onClick={() => onTabChange('signup')}
        >
          Sign Up
        </button>
      </div>
      <div
        className={`h-1 w-1/2 bg-primary-600 rounded-lg transition ${activeTab === 'signin' ? 'translate-x-0' : 'translate-x-full'}`}
      ></div>
    </div>
  );
};

export default AuthTabs;
