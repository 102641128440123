import React, { useEffect, useState } from 'react';
import SectionTitle from '../common/SectionTitle';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { fetchLatestAnnouncements } from '../../redux/actions/announcementsActions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import AnnouncementFormModal from './AnnouncementFormModal';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobilesm: {
    breakpoint: { max: 463, min: 280 },
    items: 1,
    slidesToSlide: 1,
  },
};

const truncateText = (text: string, maxLength: number = 105) => {
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

const LatestAnnouncements: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const announcementsData = useSelector(
    (state: any) => state.announcements || {},
  );
  const latestAnnouncements = announcementsData.latestAnnouncements || [];
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchLatestAnnouncements());
  }, [dispatch]);

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <div className="mb-12">
      <SectionTitle
        title="Latest"
        titleBadge="announcement"
        buttonTitle="Announcement"
        onButtonClick={toggleModal}
      />
      {latestAnnouncements.length === 0 ? (
        <p className="fredoka-600 text-xl my-12 mb-16">
          There's no announcement at the moment
        </p>
      ) : (
        <Carousel
          responsive={responsive}
          autoPlay={latestAnnouncements.length > 1}
          swipeable={latestAnnouncements.length > 1}
          draggable={latestAnnouncements.length > 1}
          infinite={latestAnnouncements.length > 1}
          centerMode={latestAnnouncements.length > 1}
          arrows={latestAnnouncements.length > 1} // Disable arrows if only 1 announcement
          customTransition={
            latestAnnouncements.length < 3
              ? 'transform 300ms ease-in-out'
              : undefined
          }
        >
          {latestAnnouncements
            .slice(0, Math.min(3, latestAnnouncements.length))
            .map((announcement: any, index: any) => (
              <div
                key={announcement.id}
                className="lg:min-w-[300px] min-h-[280px] max-w-[50%] bg-primary-600 pt-8 pb-12 px-4 rounded-2xl shadow-md mr-2 lg:mr-6 flex flex-col lg:flex-row items-center lg:items-start justify-between gap-8 relative select-none"
              >
                <div className="bg-white w-28 h-28 md:h-48 md:w-48 rounded-full flex-shrink-0 relative flex flex-row-reverse items-center justify-center">
                  <img
                    className="absolute top-0 left-4"
                    src="/assets/icons/love.png"
                    alt="decoration"
                    draggable="false"
                  />
                  <img
                    src={`https://inidevloh.coppamagz.com/${announcement.image}`}
                    alt={announcement.title}
                    className={`w-8/12 lg:w-8/12 ${
                      announcement.image2
                        ? '-translate-y-1/2 -translate-x-1/3'
                        : ''
                    }`}
                    draggable="false"
                  />
                  {announcement.image2 && (
                    <img
                      src={`https://inidevloh.coppamagz.com/${announcement.image2}`}
                      alt={announcement.title}
                      className="w-8/12 lg:w-8/12 translate-y-1/2 translate-x-1/3"
                      draggable="false"
                    />
                  )}
                </div>
                <div className="fredoka-600 text-center lg:text-right lg:pr-8 relative">
                  <h3 className="text-base md:text-xl lg:text-3xl font-semibold text-[#FFECC7] line-clamp-2">
                    {announcement.title || 'Default Title'}
                  </h3>
                  <p className="text-sm md:text-base text-white mt-2">
                    {announcement.content
                      ? truncateText(announcement.content)
                      : 'Default Description'}
                  </p>
                </div>
                <img
                  src="/assets/loves.png"
                  alt="decoration"
                  className="absolute top-0 right-5 w-40"
                  draggable="false"
                />
                <img
                  src="/assets/girl-boy.png"
                  alt="decoration"
                  className="absolute bottom-0 right-0 w-56"
                  draggable="false"
                />
              </div>
            ))}
        </Carousel>
      )}

      <AnnouncementFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default LatestAnnouncements;
