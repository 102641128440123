import { z } from 'zod';
import { checkPasswordBreach } from './SignUpSchema';

export const ForgotPasswordSchema = z
  .object({
    email: z.string().email('Alamat email tidak valid'),
    token: z.string().min(1, 'Tokan harus diisi'),
    password: z.string().min(1, 'Password harus diisi'),
    password_confirmation: z.string().min(1, 'Confirm Password harus diisi'),
  })
  .refine((data) => data.password === data.password_confirmation, {
    path: ['password_confirmation'],
    message: 'Konfirmasi password tidak sesuai.',
  })
  .superRefine(async (data, ctx) => {
    const isBreached = await checkPasswordBreach(data.password);
    if (isBreached) {
      ctx.addIssue({
        code: 'custom', // Use 'custom' instead of 'unrecognized_keys'
        path: ['password'],
        message:
          'The given password has appeared in a data leak. Please choose a different password.',
      });
    }
  });

export type FormFields = z.infer<typeof ForgotPasswordSchema>;
