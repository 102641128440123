import { useState, useEffect, useRef } from 'react';
import { signUp } from '../../redux/actions/signUpActions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FormFields } from '../../schemas/SignUpSchema';
import { AppDispatch } from '../../redux/store';

const TermsOfUseForm = ({ data }: { data: FormFields }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [agreeToAll, setAgreeToAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    termsOfUse: false,
    accountTerms: false,
    personalInformation: false,
    over16: false,
    promotionalInfo: false,
    marketingConsent: false,
  });
  const [showTerms, setShowTerms] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  // Automatically update "I agree to all terms" based on individual checkboxes
  useEffect(() => {
    const allChecked = Object.values(checkboxes).every((checked) => checked);
    setAgreeToAll(allChecked);
  }, [checkboxes]);

  const handleAgreeToAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setAgreeToAll(isChecked);
    setCheckboxes({
      termsOfUse: isChecked,
      accountTerms: isChecked,
      personalInformation: isChecked,
      over16: isChecked,
      promotionalInfo: isChecked,
      marketingConsent: isChecked,
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setCheckboxes((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  // Check if all required checkboxes are ticked
  const areRequiredCheckboxesChecked = () =>
    checkboxes.termsOfUse &&
    checkboxes.accountTerms &&
    checkboxes.personalInformation &&
    checkboxes.over16;

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Ensure required checkboxes are checked
    if (!areRequiredCheckboxesChecked()) {
      toast.error('Please check all required boxes before proceeding.', {
        autoClose: 3000,
        pauseOnHover: true,
      });
      return;
    }

    try {
      const transformedData = {
        ...data,
        gender: data.gender ? data.gender.toLowerCase() : '',
      };

      dispatch(signUp(transformedData));
    } catch (error) {
      toast.error('An error occurred during registration');
    }
  };

  // Close modal when pressing Escape key
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowTerms(false);
      }
    };

    if (showTerms) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showTerms]);

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setShowTerms(false);
      }
    };

    if (showTerms) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showTerms]);

  return (
    <div className="text-left">
      <div className="flex justify-start">
        <img
          src="/assets/logo-illustration.png"
          alt="Logo"
          className="w-20 mb-3"
        />
      </div>
      <h2 className="text-xl font-semibold mb-4">
        Please consent to Terms of Use and enjoy Coppamagz more!
      </h2>

      <p
        className="text-primary-600 cursor-pointer underline mb-4"
        onClick={() => setShowTerms(true)}
      >
        View Terms of Use
      </p>

      <form onSubmit={handleSubmit}>
        <div>
          <div className="mb-4 text-left flex items-start justify-start">
            <input
              type="checkbox"
              id="agreeToAll"
              checked={agreeToAll}
              onChange={handleAgreeToAllChange}
              className="checkbox-round"
            />
            <label htmlFor="agreeToAll" className="ml-2"></label>
            <p className="cursor-pointer">I agree to all terms</p>
          </div>

          {/* Individual checkboxes */}
          {[
            {
              id: 'termsOfUse',
              label: '(Required) I agree to the Terms of Use for Coppamagz',
            },
            {
              id: 'accountTerms',
              label:
                '(Required) I agree to the Terms of Use for Coppamagz Account',
            },
            {
              id: 'personalInformation',
              label:
                '(Required) I agree to the collection and usage of personal information for Coppamagz',
            },
            { id: 'over16', label: '(Required) I am over 16 years old' },
            {
              id: 'promotionalInfo',
              label: '(Optional) Consent to receive promotional information',
            },
            {
              id: 'marketingConsent',
              label:
                '(Optional) Consent to collect and use personal information for marketing purposes',
            },
          ].map(({ id, label }) => (
            <div
              key={id}
              className="mb-4 text-left flex items-start justify-start"
            >
              <input
                type="checkbox"
                id={id}
                name={id}
                checked={checkboxes[id as keyof typeof checkboxes]}
                onChange={handleCheckboxChange}
                className="checkbox-round"
              />
              <label htmlFor={id} className="ml-2"></label>
              <p>{label}</p>
            </div>
          ))}

          {showTerms && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-20">
              <div
                className="bg-white p-6 rounded-lg max-w-lg max-h-[800px] overflow-y-auto"
                ref={modalRef}
              >
                <h3 className="text-xl font-bold mb-4">Terms of Use</h3>
                <p className="mb-4">
                  Welcome to Coppamagz, a platform dedicated to connecting and
                  engaging the K-pop community through events, content, and
                  interactions. By using Coppamagz, you agree to the following
                  terms and conditions. Please read them carefully before
                  proceeding.
                </p>

                <ol className="list-decimal ml-4 space-y-2">
                  <li>
                    Acceptance of Terms (Required)
                    <br />
                    By checking the box "I agree to the Terms of Use for
                    Coppamagz," you acknowledge that you have read, understood,
                    and agree to be bound by these Terms of Use. If you do not
                    agree to these terms, you may not access or use the
                    Coppamagz platform.
                  </li>
                  <li>
                    Coppamagz Account Terms (Required)
                    <br />
                    By checking the box "I agree to the Terms of Use for
                    Coppamagz Account," you agree to:
                    <ul className="list-disc ml-4 space-y-1">
                      <li>
                        Provide accurate and complete information when creating
                        your account.
                      </li>
                      <li>
                        Maintain the security of your account credentials and
                        not share them with others.
                      </li>
                      <li>
                        Notify Coppamagz immediately of any unauthorized use of
                        your account or breach of security.
                      </li>
                      <li>
                        Accept responsibility for all activities that occur
                        under your account. Coppamagz reserves the right to
                        suspend or terminate your account if you violate these
                        terms or engage in any harmful or inappropriate behavior
                        on the platform.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Collection and Usage of Personal Information (Required)
                    <br />
                    By checking the box "I agree to the collection and usage of
                    personal information for Coppamagz," you consent to
                    Coppamagz collecting, storing, and using your personal
                    information as outlined in our Privacy Policy. This
                    includes, but is not limited to:
                    <ul className="list-disc ml-4 space-y-1">
                      <li>Your name, email address, and age.</li>
                      <li>Event preferences and participation history.</li>
                      <li>
                        Device and usage data for platform optimization. Your
                        personal information will be used to provide and improve
                        our services, communicate with you, and ensure a safe
                        and enjoyable experience on the platform.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Age Requirement (Required)
                    <br />
                    By checking the box "I am over 16 years old," you confirm
                    that you meet the minimum age requirement to use Coppamagz.
                    Users under the age of 16 are not permitted to create an
                    account or use the platform.
                  </li>
                  <li>
                    Consent to Receive Promotional Information (Optional)
                    <br />
                    By checking the box "Consent to receive promotional
                    information," you agree to receive promotional emails,
                    newsletters, and other marketing materials from Coppamagz.
                    You may opt out of receiving these communications at any
                    time by updating your preferences in your account settings
                    or using the unsubscribe link provided in the emails.
                  </li>
                  <li>
                    Consent to Collect and Use Personal Information for
                    Marketing Purposes (Optional)
                    <br />
                    By checking the box "Consent to collect and use personal
                    information for marketing purposes," you agree to allow
                    Coppamagz to use your personal information, such as your
                    name, email address, and event preferences, for targeted
                    marketing campaigns, advertisements, and promotional
                    activities.
                  </li>
                  <li>
                    User Responsibilities
                    <br />
                    As a user of Coppamagz, you agree to:
                    <ul className="list-disc ml-4 space-y-1">
                      <li>
                        Respect the rights and privacy of other users. * Not
                        engage in any form of harassment, hate speech, or
                        inappropriate behavior.
                      </li>
                      <li>
                        Not upload or share content that is illegal, offensive,
                        or violates intellectual property rights.
                      </li>
                      <li>Comply with all applicable laws and regulations.</li>
                    </ul>
                  </li>
                  <li>
                    Modifications to Terms <br />
                    Coppamagz reserves the right to update or modify these Terms
                    of Use at any time. Any changes will be communicated to you
                    via email or through the platform. Your continued use of
                    Coppamagz after such changes constitutes your acceptance of
                    the updated terms.
                  </li>
                  <li>
                    Limitation of Liability
                    <br />
                    Coppamagz is not liable for any damages or losses resulting
                    from your use of the platform, including but not limited to:
                    <ul className="list-disc ml-4 space-y-1">
                      <li>Errors or interruptions in service.</li>
                      <li>Unauthorized access to your account.</li>
                      <li>Content posted by other users.</li>
                    </ul>
                  </li>
                  <li>
                    Governing Law
                    <br />
                    These Terms of Use are governed by and construed in
                    accordance with the laws of Indonesia. Any disputes arising
                    from your use of Coppamagz will be resolved exclusively in
                    the courts of Indonesia.
                  </li>
                </ol>
                <h3 className="font-semibold mt-4 mb-2">Contact Information</h3>
                <p>
                  If you have any questions or concerns about these Terms of
                  Use, please contact us at:
                  <br />
                  <br />
                  Email: support@community.coppamagz.com
                  <br />
                  Address: Address Jl. Kedoya Alamanda V No.10 Blok E6,
                  RT.16/RW.4, South Kedoya, Kebonjeruk, West Jakarta City,
                  Jakarta 11520
                  <br />
                  <br />
                  By proceeding, you confirm that you have read, understood, and
                  agreed to the Coppamagz Terms of Use. Thank you for being a
                  part of our K-pop community!
                </p>
                <button
                  onClick={() => setShowTerms(false)}
                  className="mt-4 px-4 py-2 bg-primary-600 text-white rounded"
                >
                  Close
                </button>
              </div>
            </div>
          )}

          {/* Register Button */}
          <div className="text-center">
            <button
              type={areRequiredCheckboxesChecked() ? 'submit' : 'button'}
              disabled={!areRequiredCheckboxesChecked()}
              className="w-full border border-transparent bg-primary-600 text-white text-lg py-2 rounded-md hover:bg-primary-700 transition disabled:bg-gray-400 disabled:cursor-not-allowed"
            >
              Register Me!
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TermsOfUseForm;
