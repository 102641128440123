import axios from 'axios';
import { toast } from 'react-toastify';
import { AppDispatch } from '../store';
import http from '../services/httpService';

export const signUp =
  (transformedData: any) => async (dispatch: AppDispatch) => {
    dispatch({ type: 'SIGNUP_REQUEST' });

    try {
      const response = await http.post('/register', transformedData);

      if (response.status === 201) {
        dispatch({
          type: 'SIGNUP_SUCCESS',
        });

        toast.success('Registration successful! Redirecting to login.', {
          autoClose: 3000,
          pauseOnHover: false,
          onClose: () => {
            dispatch({ type: 'SIGNUP_LOADING_FINISHED' });
            window.location.reload();
          },
        });
      }
    } catch (error) {
      let errorMessage = 'An unexpected error occurred.';

      if (axios.isAxiosError(error) && error.response) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = Object.values(error.response.data.message).join(', ');
        }
      }

      dispatch({
        type: 'SIGNUP_FAILURE',
        payload: errorMessage,
      });

      toast.error(errorMessage);
    }
  };
