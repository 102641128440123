import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfilePicture } from '../../redux/actions/profileActions';
import { RootState } from '../../redux/store';
import { toast } from 'react-toastify';

interface ProfilePictureUploadProps {
  currentProfilePictureUrl: string | null;
  onUpload: (file: File) => void;
}

const ProfilePictureUpload: React.FC<ProfilePictureUploadProps> = ({
  currentProfilePictureUrl,
  onUpload,
}) => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state: RootState) => state.profile);

  const [imageUrl, setImageUrl] = useState<string | null>(
    `https://inidevloh.coppamagz.com/${currentProfilePictureUrl}`,
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const newImageUrl = URL.createObjectURL(file);
    setImageUrl(newImageUrl);

    dispatch(updateProfilePicture(file) as any)
      .then(() => {
        onUpload(file);

        toast.success('Profile picture updated successfully!');

        window.location.reload();
      })
      .catch((_error: any) => {
        toast.error('Failed to update profile picture.');
      });
  };

  const displayImageUrl = imageUrl
    ? imageUrl
    : 'https://placehold.co/150x150/png';

  return (
    <>
      <div className="relative w-28 h-28">
        <div className="w-full h-full border-3 border-primary-550 rounded-full overflow-hidden">
          <img
            src={
              currentProfilePictureUrl
                ? displayImageUrl
                : 'https://placehold.co/150x150/png'
            }
            alt="Profile"
            className="w-full h-full object-cover"
          />
        </div>
      </div>

      <label
        htmlFor="profile-picture-upload"
        className="bg-primary-550 text-white rounded-lg px-4 py-2 cursor-pointer"
      >
        {loading ? 'Uploading...' : 'Change Photo'}
      </label>
      <input
        id="profile-picture-upload"
        type="file"
        accept="image/*"
        className="hidden"
        onChange={handleFileChange}
        disabled={loading}
      />
    </>
  );
};

export default ProfilePictureUpload;
