import { AppDispatch } from '../store';
import http from '../services/httpService';
import { toast } from 'react-toastify';

export const fetchUserEvents =
  (page: number = 1, perPage: number = 8) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: 'USER_EVENTS_REQUEST' });

    try {
      const response = await http.get(
        `/profile/user-event?perPage=${perPage}&page=${page}`,
      );
      const data = response.data;

      dispatch({
        type: 'USER_EVENTS_SUCCESS',
        payload: {
          events: data.data.data,
          pagination: {
            currentPage: data.data.current_page,
            lastPage: data.data.last_page,
            total: data.data.total,
            nextPage: data.data.next_page_url,
            prevPage: data.data.prev_page_url,
          },
        },
      });
    } catch (error: any) {
      toast.error(
        error.response?.data?.message || 'Failed to fetch user events.',
      );
      dispatch({
        type: 'USER_EVENTS_FAILURE',
        payload: error.response?.data?.message || 'Something went wrong',
      });
    }
  };

export const fetchUserUpcomingEvents =
  (page: number = 1, perPage: number = 1) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: 'USER_UPCOMING_EVENTS_REQUEST' });

    try {
      const response = await http.get(
        `/profile/user-event?perPage=${perPage}&page=${page}&timeFilter=upcoming`,
      );
      const data = response.data;

      dispatch({
        type: 'USER_UPCOMING_EVENTS_SUCCESS',
        payload: {
          events: data.data.data,
          pagination: {
            currentPage: data.data.current_page,
            lastPage: data.data.last_page,
            total: data.data.total,
            nextPage: data.data.next_page_url,
            prevPage: data.data.prev_page_url,
          },
        },
      });
    } catch (error: any) {
      toast.error(
        error.response?.data?.message || 'Failed to fetch user events.',
      );
      dispatch({
        type: 'USER_UPCOMING_EVENTS_FAILURE',
        payload: error.response?.data?.message || 'Something went wrong',
      });
    }
  };

export const resetUserEvents = () => ({
  type: 'RESET_USER_EVENTS',
});
