import { Reducer, AnyAction } from 'redux';

interface ProfileState {
  loading: boolean;
  data: any;
  error: string | null;
}

const initialState: ProfileState = {
  loading: false,
  data: null,
  error: null,
};

const profileReducer: Reducer<ProfileState, AnyAction> = (
  state = initialState,
  action,
): ProfileState => {
  switch (action.type) {
    case 'PROFILE_UPDATE_REQUEST':
    case 'PROFILE_PICTURE_UPDATE_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'PROFILE_UPDATE_SUCCESS':
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
        error: null,
      };

    case 'PROFILE_PICTURE_UPDATE_SUCCESS':
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          profile_picture_url: `https://inidevloh.coppamagz.com/${action.payload.path}`,
        },
        error: null,
      };

    case 'PROFILE_UPDATE_FAILURE':
    case 'PROFILE_PICTURE_UPDATE_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default profileReducer;
