import ParallelogramImage from './ParallelogramImage';

const EventBannerImages: React.FC = () => {
  const banners = ['/assets/events/banner.png'];

  return (
    <div className="flex overflow-hidden w-full">
      {banners.map((imgUrl, index) => (
        <ParallelogramImage
          key={index}
          imgUrl={imgUrl}
          additionalClass="event-banner"
          index={index}
        />
      ))}
    </div>
  );
};

export default EventBannerImages;
