import { Link } from 'react-router-dom';
import Badge from './Badge';

interface BadgeInterface {
  badge_id: number;
  earned_at: string;
  status: string;
  badge: {
    id: number;
    title: string;
    description: string;
    event_id: number;
    icon: string;
    point: number;
    status: string;
  };
}

interface BadgesProps {
  badges: BadgeInterface[];
}

const Badges: React.FC<BadgesProps> = ({ badges }) => {
  return (
    <div>
      <div className="flex items-center gap-2 mb-4">
        <div>
          <Link to="/events">
            <img src="/assets/logo-coppamagz.png" alt="logo" />
          </Link>
        </div>
        <div className="relative flex items-start w-44">
          <Link to="/badges">
            <img
              src="/assets/badges-bubble.png"
              alt="qr bubble"
              className="w-full"
            />
            <img
              src="/assets/badges-decoration.png"
              alt="decoration"
              className="absolute left-5 md:left-7 top-[19px] w-5"
            />

            <h2 className="text-base md:text-lg font-bold left-1/2 top-1/2 -translate-x-[43%] -translate-y-1/2 fredoka-600 leading-5 -rotate-[3deg] w-fit text-pink-500 text-center absolute">
              BADGES & <br />
              ACHIEVEMENTS
            </h2>
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 relative">
        {badges.length > 0 ? (
          <>
            {badges.slice(0, 3).map((badge) => (
              <Badge key={badge.badge_id} badge={badge} status={badge.status} />
            ))}

            {/* "View More" Arrow Button */}
            <Link
              to="/badges"
              className="absolute right-0 top-1/2 h-[120%] -translate-y-1/2 opacity-0 hover:opacity-100 transition z-30"
            >
              <img src="/assets/arrow.png" alt="arrow" className="h-full" />
            </Link>
          </>
        ) : (
          <img
            src="/assets/badge-empty.png"
            alt="no badges"
            className="h-full"
          />
        )}
      </div>
    </div>
  );
};

export default Badges;
