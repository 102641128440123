import React, { useState } from 'react';
import EditProfileModal from './EditProfileModal';
import ProfilePicture from './ProfilePicture';

interface Fandom {
  id: number;
  name: string;
  whatsapp_url: string;
  description: string;
  image: string;
}

export interface ProfileCardProps {
  user: {
    name: string;
    member_id: string;
    email: string;
    phone_number: string;
    date_of_birth: string;
    gender: string;
    age: number;
    fandoms: Fandom[];
    profile_picture_url: string | null;
    total_point: number;
    domicile: {
      city: string;
      province: string;
      country: string;
    };
    bio?: string;
    twitter_url?: string;
    instagram_url?: string;
    facebook_url?: string;
    tiktok_url?: string;
  };
  loading: boolean;
  error: boolean;
}

function getRandomColorPair() {
  const randomHue = Math.floor(Math.random() * 360);
  const lightBackgroundLightness = Math.floor(Math.random() * 30) + 70;
  const darkTextLightness = Math.floor(Math.random() * 30) + 20;

  const bgColor = `hsl(${randomHue}, 60%, ${lightBackgroundLightness}%)`;
  const textColor = `hsl(${randomHue}, 60%, ${darkTextLightness}%)`;

  return { bgColor, textColor };
}

const ProfileCard: React.FC<ProfileCardProps> = ({ user, loading, error }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!user) return <div>No data found</div>;

  return (
    <div className="bg-white shadow-xl p-4 py-8 rounded-xl flex flex-col md:flex-row items-center md:items-start w-full">
      <div className="flex flex-col md:flex-row items-center md:items-start w-full md:w-fit grow gap-4">
        {/* Profile Picture */}
        <div className="relative w-28 h-28">
          <ProfilePicture profilePictureUrl={user.profile_picture_url} />
          <button
            onClick={handleEditClick}
            className="bg-primary-550 rounded-full p-1 absolute bottom-2 right-0"
          >
            <img src="../assets/icons/edit.png" alt="edit icon" />
          </button>
        </div>
        {/* Profile Details */}
        <div className="text-center md:text-start w-full md:w-fit">
          <p className="text-2xl font-bold">{user.name}</p>
          <p className="text-sm mt-2">
            {user.domicile.city}, {user.age} years
          </p>
          <p className="text-sm mt-2 font-semibold">
            Member ID: #{user.member_id}
          </p>
          <div className="flex flex-wrap gap-2 mt-2">
            {user.fandoms.map((fandom, index) => {
              const { bgColor, textColor } = getRandomColorPair();
              return (
                <span
                  key={index}
                  style={{
                    backgroundColor: bgColor,
                    color: textColor,
                  }}
                  className="rounded-3xl text-sm p-2"
                >
                  {fandom.name}
                </span>
              );
            })}
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-br from-[#F0FCF5] to-[#E0F8E8] rounded-xl p-6 w-full md:w-fit mt-4 md:mt-0">
        <div className="flex items-center gap-4">
          {/* <img src="./assets/star.png" alt="star" className="w-16 h-16" /> */}
          <img src="../assets/star.png" alt="star" className="w-16 h-16" />
          <div>
            <p className="font-medium">Total Points</p>
            <p className="text-5xl font-bold text-[#F2C14B]">
              {user.total_point}
            </p>
          </div>
        </div>
        <p className="text-xs font-medium tracking-wide mt-2">
          Exchange your points with some rewards
        </p>
      </div>
      {isModalOpen && (
        <EditProfileModal user={user} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default ProfileCard;
