import React, { useState } from 'react';
import AuthWrapper from '../components/auth/AuthWrapper';
import SignInForm from '../components/auth/SignInForm';
import SignUpForm from '../components/auth/SignUpForm';
import AuthTabs from '../components/auth/AuthTabs';

const AuthPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'signin' | 'signup'>('signin');

  return (
    <AuthWrapper activeTab={activeTab}>
      <div className="text-center my-8">
        {/* Tabs for SignIn and SignUp */}
        <AuthTabs activeTab={activeTab} onTabChange={setActiveTab} />

        {/* Logo and Text for SignIn Tab */}
        {activeTab === 'signin' && (
          <div className="my-10">
            <div className="flex justify-center">
              <img
                src="/assets/logo-illustration.png"
                alt="Logo"
                className="w-32 mb-3"
              />
            </div>
            <h1 className="text-2xl font-semibold text-center">
              Welcome Back!
            </h1>
            <p className="text-center">Let’s get you back to the stage!</p>
          </div>
        )}

        {/* Form Content (SignIn or SignUp) */}
        {activeTab === 'signin' ? <SignInForm /> : <SignUpForm />}
      </div>
    </AuthWrapper>
  );
};

export default AuthPage;
