import CryptoJS from 'crypto-js';
import { z } from 'zod';

export async function checkPasswordBreach(password: string): Promise<boolean> {
  const hash = CryptoJS.SHA1(password).toString().toUpperCase();
  const prefix = hash.substring(0, 5);
  const suffix = hash.substring(5);

  const response = await fetch(
    `https://api.pwnedpasswords.com/range/${prefix}`,
  );
  if (!response.ok) {
    throw new Error('Error checking password breach.');
  }

  const leakedPasswords = await response.text();
  return leakedPasswords.includes(suffix);
}

export const SignUpSchema = z
  .object({
    name: z.string().min(3, 'Nama Lengkap minimal harus 3 karakter'),
    phone_number: z
      .string()
      .min(10, 'No Handphone minimal harus 10 digit')
      .max(15, 'No Handphone maksimal harus 15 digit')
      .regex(
        /^(62|08)\d{8,13}$/,
        'Nomor handphone harus diawali dengan 62 atau 08, dan memiliki panjang 10-15 digit.',
      ),
    date_of_birth: z
      .string()
      .min(1, 'Tanggal lahir harus diisi')
      .refine((val) => new Date(val) < new Date(), {
        message: 'Tanggal lahir harus sebelum tanggal hari ini.',
      }),
    gender: z
      .string()
      .min(1, 'Gender harus dipilih')
      .refine((val) => ['male', 'female'].includes(val.toLowerCase()), {
        message: 'Gender harus Laki-laki atau Perempuan.',
      }),
    province: z.string().min(2, 'Provinsi harus diisi'),
    city: z.string().min(2, 'Kota harus diisi'),
    email: z.string().email('Alamat email tidak valid'),
    password: z
      .string()
      .min(8, 'Password minimal harus 8 karakter')
      .regex(/[A-Z]/, 'Password harus mengandung huruf kapital.')
      .regex(/[a-z]/, 'Password harus mengandung huruf kecil.')
      .regex(/\W|_/, 'Password harus mengandung simbol.'),
    password_confirmation: z
      .string()
      .min(8, 'Konfirmasi password minimal harus 8 karakter'),
  })
  .refine((data) => data.password === data.password_confirmation, {
    path: ['password_confirmation'],
    message: 'Konfirmasi password tidak sesuai.',
  })
  .superRefine(async (data, ctx) => {
    const isBreached = await checkPasswordBreach(data.password);
    if (isBreached) {
      ctx.addIssue({
        code: 'custom', // Use 'custom' instead of 'unrecognized_keys'
        path: ['password'],
        message:
          'The given password has appeared in a data leak. Please choose a different password.',
      });
    }
  });

export type FormFields = z.infer<typeof SignUpSchema>;
