import { AppDispatch } from '../store';
import http from '../services/httpService';
import { toast } from 'react-toastify';

export const fetchEvents =
  (
    page: number = 1,
    perPage: number = 8,
    searchQuery: string = '',
    replace: boolean = false,
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: 'EVENTS_REQUEST' });

    try {
      const response = await http.get(
        `/event-upcoming?timeFilter=&perPage=${perPage}&page=${page}&status=published&search=${searchQuery}`,
      );
      const data = response.data;

      dispatch({
        type: 'EVENTS_SUCCESS',
        payload: {
          events: data.data.data,
          pagination: {
            currentPage: data.data.current_page,
            lastPage: data.data.last_page,
            total: data.data.total,
            nextPage: data.data.next_page_url,
            prevPage: data.data.prev_page_url,
          },
          replace,
        },
      });
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to fetch events.');
      dispatch({
        type: 'EVENTS_FAILURE',
        payload: error.response?.data?.message || 'Something went wrong',
      });
    }
  };

export const resetEvents = () => ({
  type: 'RESET_EVENTS',
});
