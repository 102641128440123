interface ParticipantsState {
  loading: boolean;
  data: any;
  error: string | null;
}

const initialState: ParticipantsState = {
  loading: false,
  data: [],
  error: null,
};

const participantsReducer = (
  state = initialState,
  action: any,
): ParticipantsState => {
  switch (action.type) {
    case 'FETCH_PARTICIPANTS_REQUEST':
      return { ...state, loading: true, error: null };
    case 'FETCH_PARTICIPANTS_SUCCESS':
      return { ...state, loading: false, data: action.payload, error: null };
    case 'FETCH_PARTICIPANTS_FAILURE':
      return { ...state, loading: false, error: action.payload };
    case 'RESET_PARTICIPANTS':
      return initialState;
    default:
      return state;
  }
};

export default participantsReducer;
