import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Badge from '../components/profile/Badge';
import { AppDispatch } from '../redux/store';
import { getUserBadge } from '../redux/actions/getUserBadgeAction';
import { Link } from 'react-router-dom';

const Badges: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { badges } = useSelector((state: any) => state.badge);

  useEffect(() => {
    dispatch(getUserBadge());

    return () => {
      dispatch(getUserBadge());
    };
  }, [dispatch]);

  return (
    <div className="lg:container lg:mx-auto mx-6 md:p-6 py-10">
      <div className="flex items-center gap-2 mb-4">
        <div>
          <Link to="/events">
            <img src="/assets/logo-coppamagz.png" alt="logo" />
          </Link>
        </div>
        <div className="relative flex items-start w-44">
          <Link to="/badges">
            <img
              src="/assets/badges-bubble.png"
              alt="qr bubble"
              className="w-full"
            />
            <img
              src="/assets/badges-decoration.png"
              alt="decoration"
              className="absolute left-5 md:left-7 top-[19px] w-5"
            />

            <h2 className="text-base md:text-lg font-bold left-1/2 top-1/2 -translate-x-[43%] -translate-y-1/2 fredoka-600 leading-5 -rotate-[3deg] w-fit text-pink-500 text-center absolute">
              BADGES & <br />
              ACHIEVEMENTS
            </h2>
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
        {badges.length > 0 ? (
          badges.map((badge: any) => (
            <Badge key={badge.badge_id} badge={badge} status={badge.status} />
          ))
        ) : (
          <div className="col-span-full flex justify-start items-center">
            <img
              src="/assets/badge-empty.png"
              alt="No badges"
              className="h-40"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Badges;
