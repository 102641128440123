import { Reducer } from 'redux';

interface SignUpState {
  loading: boolean;
  error: string | null;
}

const initialState: SignUpState = {
  loading: false,
  error: null,
};

interface SignUpAction {
  type:
    | 'SIGNUP_REQUEST'
    | 'SIGNUP_SUCCESS'
    | 'SIGNUP_FAILURE'
    | 'SIGNUP_LOADING_FINISHED';
  payload?: any;
}

const signUpReducer: Reducer<SignUpState, SignUpAction | { type: string }> = (
  state = initialState,
  action,
): SignUpState => {
  switch (action.type) {
    case 'SIGNUP_REQUEST':
      return { ...state, loading: true, error: null };

    case 'SIGNUP_SUCCESS':
      if ('payload' in action) {
        return {
          ...state,
          error: null,
        };
      }
      return state;

    case 'SIGNUP_FAILURE':
      if ('payload' in action) {
        return { ...state, loading: false, error: String(action.payload) };
      }
      return state;

    case 'SIGNUP_LOADING_FINISHED':
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default signUpReducer;
