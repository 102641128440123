import React, { useEffect, useState, useRef } from 'react';
import getCurrentWIBTime from '../../utilities/getWIBTime';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { createAnnouncement } from '../../redux/actions/announcementsActions';
import { fetchEvents } from '../../redux/actions/eventsActions';

interface AnnouncementFormModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AnnouncementFormModal: React.FC<AnnouncementFormModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { events, pagination } = useSelector(
    (state: RootState) => state.events,
  );

  const [formData, setFormData] = useState({
    title: '',
    eventId: '',
    content: '',
    image: null as File | null,
    image2: null as File | null,
    endDate: '',
    releaseDate: '',
    status: 'draft',
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const dropdownRef = useRef<HTMLUListElement | null>(null);

  // Fetch events when input is focused
  const handleInputFocus = () => {
    setIsInputFocused(true);
    setIsDropdownVisible(true);
    if (events.length === 0) {
      dispatch(fetchEvents(1, 8, '', true));
    }
  };

  // Fetch events when search term changes
  useEffect(() => {
    dispatch(fetchEvents(1, 8, searchTerm, true));
  }, [searchTerm, dispatch]);

  // Filter events based on search term
  const filteredEvents = events.filter((event: any) =>
    event.title.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // Handle infinite scrolling in dropdown
  const handleScroll = () => {
    if (!dropdownRef.current || isFetching || !pagination?.nextPage) return;

    const { scrollTop, scrollHeight, clientHeight } = dropdownRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setIsFetching(true);
      dispatch(fetchEvents(page + 1, 8, searchTerm, false)).then(() => {
        setPage((prevPage) => prevPage + 1);
        setIsFetching(false);
      });
    }
  };

  const handleEventSelection = (event: any) => {
    setFormData((prev) => ({ ...prev, eventId: event.id }));
    setSearchTerm(event.title);
    // setIsInputFocused(false);
    setIsDropdownVisible(false);
  };

  // Close dropdown if user clicks outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (dropdownRef.current) {
        dropdownRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isFetching, pagination?.nextPage]);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrors({});

    const result = await dispatch(createAnnouncement(formData));

    if (result?.type === 'CREATE_ANNOUNCEMENT_SUCCESS') {
      setFormData({
        title: '',
        eventId: '',
        content: '',
        image: null,
        image2: null,
        endDate: '',
        releaseDate: '',
        status: 'draft',
      });
      onClose();
    } else if (result?.payload?.status_code === 422) {
      // Extract errors from backend response
      const backendErrors = result.payload.message || {};
      setErrors(backendErrors);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
      <div className="bg-white p-6 rounded-lg shadow-lg w-2/3 h-4/5 overflow-y-scroll">
        <h2 className="text-xl font-semibold mb-4">Create Announcement</h2>
        <form onSubmit={handleSubmit}>
          {/* Title */}
          <div className="mb-4">
            <label className="block text-gray-700">Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
              className="w-full p-2 border-1 border-gray-400 focus:outline-primary-600 rounded"
              required
              maxLength={100}
            />
          </div>

          {/* Associated Event */}
          <div className="mb-4 relative">
            <label className="block text-gray-700">Associated Event</label>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={handleInputFocus}
              onBlur={() => setTimeout(() => setIsInputFocused(false), 200)}
              className="w-full p-2 border-1 border-gray-400 focus:outline-primary-600 rounded"
            />
            {isDropdownVisible && (
              <ul
                ref={dropdownRef}
                className="border-1 border-gray-300 mt-2 bg-white rounded-lg shadow-md max-h-60 overflow-y-auto absolute w-full z-50"
              >
                {filteredEvents.map((event: any) => (
                  <li
                    key={event.id}
                    onClick={() => handleEventSelection(event)}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  >
                    {event.title}
                  </li>
                ))}
                {isFetching && (
                  <li className="text-center text-gray-500 py-2">Loading...</li>
                )}
              </ul>
            )}
          </div>

          {/* Content */}
          <div className="mb-4">
            <label className="block text-gray-700">Content</label>
            <textarea
              name="content"
              value={formData.content}
              onChange={(e) =>
                setFormData({ ...formData, content: e.target.value })
              }
              className="w-full p-2 border-1 border-gray-400 focus:outline-primary-600 rounded"
              required
            />
          </div>

          {/* Image Upload */}
          <div className="mb-4">
            <label className="block text-gray-700">Image</label>
            <input
              type="file"
              name="image"
              onChange={(e) => {
                if (e.target.files) {
                  setFormData({ ...formData, image: e.target.files[0] });
                }
              }}
              className="w-full p-2 border-1 border-gray-400 focus:outline-primary-600 rounded"
              required
              accept="image/jpeg, image/png, image/jpg"
            />
          </div>

          {/* End Date */}
          <div className="mb-4">
            <label className="block text-gray-700">End Date and Time</label>
            <input
              type="datetime-local"
              name="endDate"
              value={formData.endDate.slice(0, 16)}
              onChange={(e) =>
                setFormData({ ...formData, endDate: e.target.value })
              }
              className="w-full p-2 border-1 border-gray-400 focus:outline-primary-600 rounded"
              required
              min={getCurrentWIBTime()}
            />
          </div>

          {/* Release Date */}
          <div className="mb-4">
            <label className="block text-gray-700">Release Date and Time</label>
            <input
              type="datetime-local"
              name="releaseDate"
              value={formData.releaseDate.slice(0, 16)}
              onChange={(e) =>
                setFormData({ ...formData, releaseDate: e.target.value })
              }
              className="w-full p-2 border-1 border-gray-400 focus:outline-primary-600 rounded"
              required
              min={getCurrentWIBTime()}
            />
          </div>

          {/* Status */}
          <div className="mb-4">
            <label className="block text-gray-700">Status</label>
            <select
              name="status"
              value={formData.status}
              onChange={(e) =>
                setFormData({ ...formData, status: e.target.value })
              }
              className="w-full p-2 border-1 border-gray-400 focus:outline-primary-600 rounded"
              required
            >
              <option value="draft">Draft</option>
              <option value="published">Published</option>
              <option value="archived">Archived</option>
            </select>
          </div>

          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-primary-600 text-white rounded hover:bg-primary-700"
            >
              Create Announcement
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AnnouncementFormModal;
