import axios from 'axios';
import { toast } from 'react-toastify';
import { AppDispatch } from '../store';
import http from '../services/httpService';

export const signIn =
  (email: string, password: string, navigate: any) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: 'AUTH_REQUEST' });

    try {
      const response = await http.post('/login', { email, password });

      if (response.status === 200) {
        const { token } = response.data.data;
        const { role } = response.data.data.user;

        localStorage.setItem('authToken', token);
        localStorage.setItem('userRole', role);

        dispatch({
          type: 'AUTH_SUCCESS',
          payload: { token },
        });

        toast.success('Login successful! Redirecting to profile.', {
          autoClose: 3000,
          pauseOnHover: false,
          onClose: () => {
            dispatch({ type: 'AUTH_LOADING_FINISHED' });
            navigate('/profile');
          },
        });
      }
    } catch (error) {
      let errorMessage = 'An unexpected error occurred.';

      if (axios.isAxiosError(error) && error.response) {
        // Extract the error message
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = Object.values(error.response.data.message).join(', ');
        }
      }

      dispatch({
        type: 'AUTH_FAILURE',
        payload: errorMessage,
      });

      toast.error(errorMessage);
    }
  };
