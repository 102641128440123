import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { fetchUserEvents } from '../../redux/actions/fetchUserEvents';
import InfiniteScroll from 'react-infinite-scroll-component';
import EventCard from '../events/EventCard';
import SectionTitle from '../common/SectionTitle';
import { Link } from 'react-router-dom';

const HistoryEvents = () => {
  const dispatch: AppDispatch = useDispatch();
  const { events, pagination, loading, error } = useSelector(
    (state: RootState) => state.userEvents,
  );
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    dispatch(fetchUserEvents(1));
  }, [dispatch]);

  useEffect(() => {
    if (pagination) {
      setHasMore(pagination.currentPage < pagination.lastPage);
    }
  }, [pagination]);

  const fetchNextPage = async () => {
    let url = pagination?.nextPage;

    if (!url || loading) return;

    const urlObj = new URL(url);

    const page = urlObj.searchParams.get('page');

    if (page) {
      await dispatch(fetchUserEvents(Number(page)));
    }
  };

  if (loading && events.length === 0) return <p>Loading events...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="fredoka-600">
      <SectionTitle title="History" titleBadge="events-logo" />
      {events.length ? (
        <InfiniteScroll
          dataLength={events.length}
          next={fetchNextPage}
          hasMore={hasMore}
          loader={<h4>Loading more events...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {events.length ? (
              events.map((event: any, index) => (
                <EventCard
                  key={index}
                  imgSrc={`https://inidevloh.coppamagz.com/${event.event?.image}`}
                  title={event.event?.title}
                  location={event.event?.location}
                  date={new Date(event.event?.start_time).toLocaleDateString()}
                  attendance={event.event?.max_participants || '-'}
                  slug={event.event?.slug || '#'}
                />
              ))
            ) : (
              <Link to="/events">
                <img
                  alt="No History Event"
                  src="/assets/history-event-empty.png"
                  className="event-card lg:max-w-[280px] aspect-[calc(1.1)/1] lg:min-w-[280px] 2xl:min-w-[360px] relative rounded-xl overflow-hidden w-full "
                />
              </Link>
            )}
          </div>
        </InfiniteScroll>
      ) : (
        <p className="text-center">
          <b>No events available</b>
        </p>
      )}
      {/* Show loading indicator if there's more to load */}
      {loading && hasMore && <p>Loading more events...</p>}
    </div>
  );
};

export default HistoryEvents;
