interface EventDetailState {
  loading: boolean;
  data: any;
  error: string | null;
}

const initialState: EventDetailState = {
  loading: false,
  data: null,
  error: null,
};

const eventDetailReducer = (
  state = initialState,
  action: any,
): EventDetailState => {
  switch (action.type) {
    case 'FETCH_EVENT_DETAIL_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'FETCH_EVENT_DETAIL_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case 'FETCH_EVENT_DETAIL_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default eventDetailReducer;
