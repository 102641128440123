import React, { useState } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

type PasswordFieldProps = {
  register: UseFormRegisterReturn;
  placeholder: string;
  error?: FieldError;
};

const PasswordField: React.FC<PasswordFieldProps> = ({
  register,
  placeholder,
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <div className="relative">
        <input
          {...register}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          className="w-full bg-gray-200 border border-gray-300 placeholder:text-gray-400 px-4 py-2 pr-10 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600"
        />
        <button
          type="button"
          onClick={() => setShowPassword((prev) => !prev)}
          className="absolute right-1 top-1/2 transform -translate-y-1/2 text-gray-600 px-2"
        >
          <img
            src={
              showPassword
                ? '/assets/icons/eye.png'
                : '/assets/icons/eye-off.png'
            }
            alt="Toggle visibility"
          />
        </button>
      </div>
      {error && (
        <div className="text-red-500 flex flex-start text-left text-sm mt-1">
          {error.message}
        </div>
      )}
    </div>
  );
};

export default PasswordField;
