import axios from 'axios';

const fetchWithFallback = async (url: string) => {
  try {
    const response = await axios.get(
      `https://api.allorigins.win/get?url=${encodeURIComponent(url)}`,
    );
    return JSON.parse(response.data.contents).data;
  } catch (error) {
    console.warn('AllOrigins failed, trying ThingProxy:', error);
    try {
      const response = await axios.get(
        `https://thingproxy.freeboard.io/fetch/${url}`,
      );
      return response.data.data;
    } catch (fallbackError) {
      console.error('Both AllOrigins and ThingProxy failed:', fallbackError);
      return [];
    }
  }
};

export const fetchProvinces = async () => {
  return await fetchWithFallback('https://wilayah.id/api/provinces.json');
};

export const fetchCities = async (provinceCode: string) => {
  return await fetchWithFallback(
    `https://wilayah.id/api/regencies/${provinceCode}.json`,
  );
};
