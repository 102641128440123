import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Copyright from '../components/Copyright';
import Badges from '../components/profile/BadgesComponent';
import HistoryEvents from '../components/profile/HistoryEvents';
import ProfileCard from '../components/profile/ProfileCard';
import { getUserProfile } from '../redux/actions/userAction';
import { getUserBadge } from '../redux/actions/getUserBadgeAction';
import { AppDispatch } from '../redux/store';
import EventCard from '../components/events/EventCard';
import SectionTitle from '../components/common/SectionTitle';
import {
  fetchUserUpcomingEvents,
  resetUserEvents,
} from '../redux/actions/fetchUserEvents';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';

// Main Component
const Profile: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    profile: userData,
    loading,
    error,
  } = useSelector((state: any) => state.user);

  const { badges } = useSelector((state: any) => state.badge);

  const { upcomingEvents } = useSelector((state: any) => state.userEvents);
  const nearestUpcomingEvent = upcomingEvents[0];

  const [isVisible, setIsVisible] = useState(false);
  const [qrValid, setQrValid] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), 100);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getUserBadge());
    dispatch(fetchUserUpcomingEvents());

    return () => {
      dispatch(resetUserEvents());
    };
  }, [dispatch]);

  const refreshQRCode = () => {
    setQrValid(true);
    dispatch(fetchUserUpcomingEvents());
  };

  useEffect(() => {
    refreshQRCode();

    return () => {
      dispatch(resetUserEvents());
    };
  }, [dispatch]);

  useEffect(() => {
    if (nearestUpcomingEvent) {
      const timer = setInterval(() => {
        setQrValid(false);
      }, 30000);

      return () => clearInterval(timer);
    }
  }, [nearestUpcomingEvent]);

  return (
    <>
      <div className="pt-12">
        <div className="lg:container mx-4 lg:mx-auto space-y-8 mb-20">
          {/* Profile Section */}
          <ProfileCard user={userData} loading={loading} error={error} />

          <div className="flex flex-col xl:flex-row gap-6 2xl:gap-8">
            {/* Event Pass Section */}
            <section className="fredoka-600 w-full md:w-3/5 lg:w-[30%] 2xl:w-[46%] flex-grow">
              <SectionTitle title="Upcoming" titleBadge="events-logo" />

              <div className="bg-gradient-to-br from-[#F0FCF5] to-[#E0F8E8] shadow-xl p-4 py-8 rounded-xl mt-4">
                <h3 className="text-2xl font-semibold">Event Pass</h3>
                {nearestUpcomingEvent ? (
                  <div className="flex flex-col lg:flex-row items-center justify-between">
                    <div className="flex flex-col w-full lg:w-1/2 2xl:w-[55%] gap-1 mt-4">
                      {/* <div className="max-w-[250px] xl:max-w-fit xl:min-w-[300px] 2xl:min-w-[360px]"> */}
                      {nearestUpcomingEvent && (
                        <EventCard
                          imgSrc={`https://inidevloh.coppamagz.com/${nearestUpcomingEvent.event?.image}`}
                          title={nearestUpcomingEvent.event?.title}
                          location={nearestUpcomingEvent.event?.location}
                          date={new Date(
                            nearestUpcomingEvent.event?.start_time,
                          ).toLocaleDateString()}
                          attendance={
                            nearestUpcomingEvent.event?.max_participants || '-'
                          }
                          slug={nearestUpcomingEvent.event?.slug || '#'}
                        />
                      )}
                      {/* </div> */}
                    </div>
                    {nearestUpcomingEvent.event.type !== 'guest' && (
                      <div className="flex flex-col mt-28 grow gap-2 relative">
                        <div className="h-full">
                          {qrValid ? (
                            <QRCode
                              value={nearestUpcomingEvent.checkin_string}
                              className="w-2/3 lg:w-36 2xl:w-40 mx-auto lg:mx-10 xl:mx-auto relative z-10"
                            />
                          ) : (
                            <button
                              onClick={refreshQRCode}
                              className="w-44 lg:w-36 2xl:w-40 aspect-square 2xl:m-10 lg:m-14 m-10 2xl:mx-auto mx-10 relative z-10 bg-primary-550 flex items-center justify-center text-center"
                            >
                              Expired
                            </button>
                          )}
                        </div>
                        <div className="absolute -top-[32%] 2xl:-top-[35%] left-[15%] 2xl:left-[18%]">
                          <div className="w-28 2xl:w-32 relative">
                            <img
                              src="/assets/qr-bubble.png"
                              alt="qr bubble"
                              className="w-full"
                            />
                            {qrValid ? (
                              <p className="text-xs text-center mx-auto transform rotate-[-13.49deg] w-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
                                Use this QRCode <br /> to Check-in
                              </p>
                            ) : (
                              <button
                                onClick={refreshQRCode}
                                className="text-xs text-center mx-auto transform rotate-[-13.49deg] w-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
                              >
                                QRCode expired <br /> Tap to refresh
                              </button>
                            )}
                          </div>
                        </div>

                        <img
                          src="/assets/logo-illustration.png"
                          alt="Logo"
                          className={`absolute w-24 mb-3 rotate-[26.85deg] top-[17.5%] transition-all duration-500 ${
                            isVisible
                              ? 'right-[1%] lg:right-[15%] 2xl:right-[10%] -translate-y-[75%] md:-translate-y-[85%] lg:-translate-y-[80%]'
                              : 'right-[30%]'
                          }`}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <Link to="/events">
                    <img
                      alt="No Upcoming Event"
                      src="/assets/upcoming-event-empty.png"
                      className="event-card aspect-[calc(1.1)/1] lg:max-w-[280px] 2xl:min-w-[360px] relative rounded-xl overflow-hidden w-full"
                    />
                  </Link>
                )}
              </div>
            </section>

            {/* Badges & Achievements */}
            <div className="flex-grow xl:w-1/2">
              <Badges badges={badges} />
            </div>
          </div>
          {/* History Events */}
          <HistoryEvents />
        </div>
        <Copyright />
      </div>
    </>
  );
};

export default Profile;
