import { AppDispatch } from '../store';
import http from '../services/httpService';
import { toast } from 'react-toastify';

export const joinEvent = (eventId: number) => async (dispatch: AppDispatch) => {
  dispatch({ type: 'JOIN_EVENT_REQUEST' });

  try {
    const response = await http.post('/event/register', {
      event_id: eventId,
    });

    if (response.data.success) {
      dispatch({
        type: 'JOIN_EVENT_SUCCESS',
        payload: response.data,
      });
      toast.success('You have successfully joined the event!');
    } else {
      toast.error('Failed to join the event');
    }
  } catch (error: any) {
    let errorMessage = 'Failed to join the event.';

    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    }

    dispatch({
      type: 'JOIN_EVENT_FAILURE',
      payload: errorMessage,
    });

    toast.error(errorMessage);
  }
};
