interface Event {
  id: number;
  title: string;
  slug: string;
  description: string;
  image: string;
  location: string;
  start_time: string;
  end_time: string;
  type: string;
  organizer: string;
  max_participants: number | null;
  status: string;
}

interface Pagination {
  currentPage: number;
  lastPage: number;
  total: number;
  nextPage: string | null;
  prevPage: string | null;
}

interface EventsState {
  loading: boolean;
  events: Event[];
  pagination: Pagination | null;
  error: string | null;
}

const initialState: EventsState = {
  loading: false,
  events: [],
  pagination: null,
  error: null,
};

const eventsReducer = (state = initialState, action: any): EventsState => {
  switch (action.type) {
    case 'EVENTS_REQUEST':
      return { ...state, loading: true, error: null };
    case 'EVENTS_SUCCESS':
      return {
        ...state,
        loading: false,
        events: action.payload.replace
          ? action.payload.events
          : [...state.events, ...action.payload.events],
        pagination: action.payload.pagination,
      };
    case 'EVENTS_FAILURE':
      return { ...state, loading: false, error: action.payload };
    case 'RESET_EVENTS':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default eventsReducer;
