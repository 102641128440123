import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../redux/actions/profileActions';
import { AppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { ProfileCardProps } from './ProfileCard';
import ProvinceCitySelector from '../ProviceCitySelector';
import { RootState } from '../../redux/store';
import Select from 'react-select';
import { components } from 'react-select';
import { fetchFandoms } from '../../redux/actions/fandomsActions';
import ProfilePictureUpload from './ProfilePictureUpload';
import formatDateForInputWIB from '../../utilities/formatDateForInput';

interface EditProfileModalProps {
  user: ProfileCardProps['user'];
  onClose: () => void;
}

const EditProfileModal: React.FC<EditProfileModalProps> = ({
  user,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { error } = useSelector((state: RootState) => state.profile);
  const fandomData = useSelector((state: RootState) => state.fandom.data);

  const [formData, setFormData] = useState({
    name: user.name,
    phone_number: user.phone_number,
    date_of_birth: formatDateForInputWIB(user.date_of_birth),
    gender: user.gender,
    city: user.domicile.city,
    province: user.domicile.province,
    fandom_id: user.fandoms.map((fandom) => fandom.id),
    bio: user.bio || '',
    twitter_url: user.twitter_url || '',
    instagram_url: user.instagram_url || '',
    facebook_url: user.facebook_url || '',
    tiktok_url: user.tiktok_url || '',
  });

  const [_, setProfilePicture] = useState<File | null>(null);

  useEffect(() => {
    dispatch(fetchFandoms());
  }, [dispatch]);

  const handleFandomChange = (selectedOptions: any) => {
    const fandomIds = selectedOptions.map((option: any) => option.value);
    setFormData((prevData) => ({ ...prevData, fandom_id: fandomIds }));
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = e.target;

    if (name === 'date_of_birth') {
      if (!value) return; // Avoid processing empty input
      const formattedDate = formatDateForInputWIB(value);
      setFormData((prevData) => ({ ...prevData, [name]: formattedDate }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleProvinceChange = (province: string) => {
    setFormData((prevData) => ({ ...prevData, province }));
  };

  const handleCityChange = (city: string) => {
    setFormData((prevData) => ({ ...prevData, city }));
  };

  const handleProfilePictureUpload = (file: File) => {
    setProfilePicture(file);
  };

  const handleSubmit = () => {
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => {
        if (typeof value === 'string') {
          return value.trim() !== '';
        }
        return true;
      }),
    );

    if (
      filteredFormData.date_of_birth &&
      typeof filteredFormData.date_of_birth === 'string'
    ) {
      const date = new Date(filteredFormData.date_of_birth);

      if (!isNaN(date.getTime())) {
        date.setUTCHours(date.getUTCHours() + 7);
        filteredFormData.date_of_birth = date.toISOString().split('T')[0];
      }
    }

    dispatch(updateProfile(filteredFormData, onClose, navigate));
  };

  // Function to check if error is an object (e.g., field-specific errors)
  const getErrorMessage = (field: string) => {
    if (typeof error === 'string') {
      return error;
    }
    if (error && typeof error === 'object' && error[field]) {
      return error[field];
    }
    return '';
  };

  const fandomOptions = fandomData?.map((fandom: any) => ({
    value: fandom.id,
    label: fandom.name,
  }));

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg w-full md:w-2/3 xl:w-1/3 h-full md:h-4/5 overflow-y-scroll">
        <h2 className="text-xl font-bold mb-4">Edit Profile</h2>
        <div className="flex flex-col items-center justify-center gap-2 border-bottom-2 mb-8">
          <ProfilePictureUpload
            currentProfilePictureUrl={user.profile_picture_url}
            onUpload={handleProfilePictureUpload}
          />
        </div>

        <div className="space-y-4">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Name"
            className="w-full bg-gray-200 placeholder:text-gray-400 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600"
          />
          {getErrorMessage('name') && (
            <div className="text-red-500 text-left text-sm mt-1">
              {getErrorMessage('name')}
            </div>
          )}
          <input
            type="text"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleInputChange}
            placeholder="Phone Number"
            className="w-full bg-gray-200 placeholder:text-gray-400 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600"
          />
          {getErrorMessage('phone_number') && (
            <div className="text-red-500 text-left text-sm mt-1">
              {getErrorMessage('phone_number')}
            </div>
          )}
          <input
            type="date"
            name="date_of_birth"
            value={formData.date_of_birth}
            onChange={handleInputChange}
            placeholder="Date of Birth"
            className="w-full bg-gray-200 placeholder:text-gray-400 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600"
          />
          {getErrorMessage('date_of_birth') && (
            <div className="text-red-500 text-left text-sm mt-1">
              {getErrorMessage('date_of_birth')}
            </div>
          )}
          <select
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
            className="w-full bg-gray-200 placeholder:text-gray-400 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600"
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          {getErrorMessage('gender') && (
            <div className="text-red-500 text-left text-sm mt-1">
              {getErrorMessage('gender')}
            </div>
          )}
          <ProvinceCitySelector
            initialProvince={user.domicile.province}
            initialCity={user.domicile.city}
            onProvinceChange={handleProvinceChange}
            onCityChange={handleCityChange}
          />
          {getErrorMessage('city') && (
            <div className="text-red-500 text-left text-sm mt-1">
              {getErrorMessage('city')}
            </div>
          )}

          <div>
            {fandomData && fandomData.length > 0 ? (
              <Select
                isMulti
                name="fandom_id"
                value={fandomOptions.filter((option: { value: number }) =>
                  formData.fandom_id.includes(option.value),
                )}
                onChange={handleFandomChange}
                options={fandomOptions}
                className="w-full bg-transparent"
                classNamePrefix="react-select"
                placeholder="Select fandoms"
                isClearable
                closeMenuOnSelect={false}
                components={{
                  MultiValueContainer: (props: any) => {
                    return (
                      <components.MultiValueContainer {...props}>
                        <span className="bg-primary-400 text-primary-700 px-2 py-1 rounded-full">
                          {props.data.label}
                        </span>
                      </components.MultiValueContainer>
                    );
                  },
                }}
              />
            ) : (
              <div className="text-gray-500">Loading fandoms...</div>
            )}
          </div>
          {getErrorMessage('fandom_id') && (
            <div className="text-red-500 text-left text-sm mt-1">
              {getErrorMessage('fandom_id')}
            </div>
          )}

          <textarea
            name="bio"
            value={formData.bio}
            onChange={handleInputChange}
            placeholder="Short bio"
            className="w-full bg-gray-200 placeholder:text-gray-400 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600"
            rows={3}
          />
          {getErrorMessage('bio') && (
            <div className="text-red-500 text-left text-sm mt-1">
              {getErrorMessage('bio')}
            </div>
          )}
          <input
            type="url"
            name="twitter_url"
            value={formData.twitter_url}
            onChange={handleInputChange}
            placeholder="Twitter URL"
            className="w-full bg-gray-200 placeholder:text-gray-400 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600"
          />
          {getErrorMessage('twitter_url') && (
            <div className="text-red-500 text-left text-sm mt-1">
              {getErrorMessage('twitter_url')}
            </div>
          )}
          <input
            type="url"
            name="instagram_url"
            value={formData.instagram_url}
            onChange={handleInputChange}
            placeholder="Instagram URL"
            className="w-full bg-gray-200 placeholder:text-gray-400 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600"
          />
          {getErrorMessage('instagram_url') && (
            <div className="text-red-500 text-left text-sm mt-1">
              {getErrorMessage('instagram_url')}
            </div>
          )}
          <input
            type="url"
            name="facebook_url"
            value={formData.facebook_url}
            onChange={handleInputChange}
            placeholder="Facebook URL"
            className="w-full bg-gray-200 placeholder:text-gray-400 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600"
          />
          {getErrorMessage('facebook_url') && (
            <div className="text-red-500 text-left text-sm mt-1">
              {getErrorMessage('facebook_url')}
            </div>
          )}
          <input
            type="url"
            name="tiktok_url"
            value={formData.tiktok_url}
            onChange={handleInputChange}
            placeholder="TikTok URL"
            className="w-full bg-gray-200 placeholder:text-gray-400 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600"
          />
          {getErrorMessage('tiktok_url') && (
            <div className="text-red-500 text-left text-sm mt-1">
              {getErrorMessage('tiktok_url')}
            </div>
          )}
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="mr-2 px-4 py-2 bg-gray-300 rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-primary-550 text-white rounded"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProfileModal;
