import { Reducer } from 'redux';

interface ForgotPasswordState {
  loading: boolean;
  error: string | null;
  message: string | null; // Added message state for success responses
}

const initialState: ForgotPasswordState = {
  loading: false,
  error: null,
  message: null,
};

interface ForgotPasswordAction {
  type:
    | 'FORGOT_PASSWORD_REQUEST'
    | 'FORGOT_PASSWORD_SUCCESS'
    | 'FORGOT_PASSWORD_FAILURE'
    | 'FORGOT_PASSWORD_LOADING_FINISHED'
    | 'SEND_TOKEN_REQUEST'
    | 'SEND_TOKEN_SUCCESS'
    | 'SEND_TOKEN_FAILURE'
    | 'SEND_TOKEN_LOADING_FINISHED';
  payload?: any;
}

const forgotPasswordReducer: Reducer<
  ForgotPasswordState,
  ForgotPasswordAction | { type: string }
> = (state = initialState, action): ForgotPasswordState => {
  switch (action.type) {
    // Forgot Password Actions
    case 'FORGOT_PASSWORD_REQUEST':
      return { ...state, loading: true, error: null, message: null };

    case 'FORGOT_PASSWORD_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        message: 'Password reset successful!',
      };

    case 'FORGOT_PASSWORD_FAILURE':
      if ('payload' in action) {
        return { ...state, loading: false, error: String(action.payload) };
      }
      return state;

    case 'FORGOT_PASSWORD_LOADING_FINISHED':
      return { ...state, loading: false };

    // Send Token Actions
    case 'SEND_TOKEN_REQUEST':
      return { ...state, loading: true, error: null, message: null };

    case 'SEND_TOKEN_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        message: 'Reset link sent! Please check your email.',
      };

    case 'SEND_TOKEN_FAILURE':
      if ('payload' in action) {
        const errorMessage = String(action.payload);
        const formattedError = errorMessage.includes(
          'A reset link has already been sent',
        )
          ? 'Wait for another 1 minute to request Token'
          : errorMessage;

        return {
          ...state,
          loading: false,
          error: formattedError,
          message: null,
        };
      }
      return state;
    case 'SEND_TOKEN_LOADING_FINISHED':
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default forgotPasswordReducer;
