interface FandomState {
  loading: boolean;
  data: any;
  error: string | null;
}

const initialState: FandomState = {
  loading: false,
  data: null,
  error: null,
};

const fandomReducer = (state = initialState, action: any): FandomState => {
  switch (action.type) {
    case 'FETCH_FANDOMS_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'FETCH_FANDOMS_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case 'FETCH_FANDOMS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default fandomReducer;
